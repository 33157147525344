.form {
  color: #1D1D1D;
}

.form:after {
  content: "";
  display: block;
  clear: both;
}

@media only screen and (max-width: 768px) {
  .form {
    padding: 0 10px;
  }
}

.form .selectedItem {
  color: #a37b24 !important;
  border-color: #a37b24 !important;
}

.form .threeQuarterBox {
  display: flex;
  justify-content: space-between;
}

.form .threeQuarterBox .firstWrapper {
  width: 60%;
  margin-right: 10px;
}

.form .threeQuarterBox .secondWrapper {
  width: 40%;
}

@media only screen and (max-width: 480px) {
  .form .threeQuarterBox {
    flex-direction: column;
  }
  .form .threeQuarterBox .firstWrapper,
  .form .threeQuarterBox .secondWrapper {
    width: 100%;
    max-width: 100%;
    margin-right: 0;
  }
}

.form .formSectionHeading {
  margin: 20px 0;
  font-family: "Nunito-Bold";
  font-weight: bold;
  font-size: 18px;
  color: #1D1D1D;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

@media only screen and (max-width: 768px) {
  .form .formSectionHeading {
    margin: 10px 0;
    font-size: 14px;
  }
}

.form label {
  cursor: pointer;
}

.form .formGroup {
  position: relative;
  display: flex;
  min-height: 55px;
  margin: 30px 0;
  font-family: "Nunito-Regular";
  font-size: 14px;
}

.form .formGroup.nonLabel .formLabel {
  display: none;
}

.form .formGroup.nonLabel .formInput {
  width: 100%;
}

.form .formGroup.fullWidth {
  flex-direction: column;
}

.form .formGroup.fullWidth .formLabel, .form .formGroup.fullWidth .formInput {
  width: 100%;
}

.form .formGroup.fullWidth .formLabel {
  margin-bottom: 10px;
  padding-right: 0;
}

.form .formGroup .formLabel {
  width: 30%;
  padding-right: 20px;
  font-family: "Nunito-Bold";
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #1D1D1D;
  cursor: pointer;
}

.form .formGroup .formInput {
  width: 70%;
  /* Override Placeholder */
  /* Override textarea */
  /* Override Select2 */
}

.form .formGroup .formInput .formControl {
  width: 100%;
  padding: 0 14px;
  font-family: "Nunito-Regular";
  font-size: 14px;
  text-transform: initial;
  color: #1D1D1D;
  height: 47px;
  border: 1px solid #C4C4C4;
  box-shadow: none !important;
  border-radius: 0;
  transition: .3s;
}

.form .formGroup .formInput .formControl:focus {
  border-color: #a37b24;
}

.form .formGroup .formInput .formControl:disabled {
  background-color: #F6F6F6;
  cursor: not-allowed;
}

.form .formGroup .formInput .inputGroup {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.form .formGroup .formInput .inputGroup .inputGroupPrepend {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0;
  background-color: #1D1D1D;
}

.form .formGroup .formInput .inputGroup .inputGroupPrepend .inputGroupPrependText {
  padding: 0 15px;
  font-family: "Nunito-Bold";
  font-size: 16px;
  letter-spacing: 1px;
  color: #fff;
}

.form .formGroup .formInput .inputGroup .inputGroupPrepend .select2 {
  height: 100%;
  padding: 0;
  border: none !important;
}

.form .formGroup .formInput .inputGroup .inputGroupPrepend .select2 .select2-selection {
  background-color: #1D1D1D;
}

.form .formGroup .formInput .inputGroup .inputGroupPrepend .select2 .select2-selection .select2-selection__rendered {
  height: 100%;
  color: #fff;
}

.form .formGroup .formInput .inputGroup .inputGroupPrepend .select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #fff transparent transparent transparent;
}

.form .formGroup .formInput .inputGroup .inputGroupPrepend .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #fff transparent;
}

.form .formGroup .formInput .inputGroup .inputGroupPrepend.countrySelection {
  min-width: 100px;
  background-color: #fff;
  border-top: 1px solid #C4C4C4;
  border-left: 1px solid #C4C4C4;
  border-bottom: 1px solid #C4C4C4;
}

.form .formGroup .formInput .inputGroup .inputGroupPrepend.countrySelection .inputGroupPrependText {
  width: 100%;
  height: 100%;
  padding: 0;
  font-family: "Nunito-Bold";
  font-size: 14px;
}

.form .formGroup .formInput .inputGroup .inputGroupPrepend.countrySelection .inputGroupPrependText span {
  font-family: "Nunito-Bold";
  font-size: 14px;
}

.form .formGroup .formInput .inputGroup .inputGroupPrepend.countrySelection .inputGroupPrependText .optionImage {
  width: 25px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
}

.form .formGroup .formInput .inputGroup .inputGroupPrepend.countrySelection .inputGroupPrependText .optionImage img {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .form .formGroup .formInput .inputGroup .inputGroupPrepend.countrySelection .inputGroupPrependText {
    font-size: 10px;
  }
  .form .formGroup .formInput .inputGroup .inputGroupPrepend.countrySelection .inputGroupPrependText .optionImage {
    width: 22px;
  }
  .form .formGroup .formInput .inputGroup .inputGroupPrepend.countrySelection .inputGroupPrependText span {
    font-size: 10px;
  }
}

.form .formGroup .formInput .inputGroup .inputGroupPrepend.countrySelection .select2 .select2-selection {
  background-color: #fff;
}

.form .formGroup .formInput .inputGroup .inputGroupPrepend.countrySelection .select2 .select2-selection .select2-selection__rendered {
  height: 100%;
  color: #1D1D1D;
}

.form .formGroup .formInput .inputGroup .inputGroupPrepend.countrySelection .select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #1D1D1D transparent transparent transparent;
}

.form .formGroup .formInput .inputGroup .inputGroupPrepend.countrySelection .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #1D1D1D transparent;
}

.form .formGroup .formInput .inputGroup .formControl {
  flex: 1;
}

.form .formGroup .formInput .passwordInput {
  position: relative;
}

.form .formGroup .formInput .passwordInput input[type="password"].formControl {
  padding: 0 40px 0 14px;
}

.form .formGroup .formInput .passwordInput .togglePassword {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  cursor: pointer;
}

.form .formGroup .formInput .passwordInput .togglePassword:before {
  content: "";
  width: 20px;
  height: 20px;
  background-image: url("/images/version-2/icons/eye-slash.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  float: right;
}

.form .formGroup .formInput .passwordInput .togglePassword.open:before {
  background-image: url("/images/version-2/icons/eye.png");
}

.form .formGroup .formInput .passwordInput .togglePassword:after {
  content: '';
  display: block;
  clear: both;
}

.form .formGroup .formInput input::placeholder, .form .formGroup .formInput select::placeholder {
  color: #999;
}

.form .formGroup .formInput textarea.formControl {
  padding: 14px;
  height: 100px;
  box-shadow: none !important;
  border-radius: 0;
}

.form .formGroup .formInput textarea.formControl::placeholder {
  font-size: 14px;
  color: #999;
}

@media only screen and (max-width: 768px) {
  .form .formGroup .formInput textarea.formControl::placeholder {
    font-size: 10px;
    text-align: left;
  }
}

.form .formGroup .formInput .select2 {
  border: 1px solid #C4C4C4;
  box-shadow: none !important;
  border-radius: 0;
  transition: .3s;
}

.form .formGroup .formInput .select2.select2-container--focus, .form .formGroup .formInput .select2.select2-container--open {
  border-color: #a37b24;
}

.form .formGroup .formInput .select2 .select2-selection {
  height: 100%;
  padding: 0 6px;
}

.form .formGroup .formInput .select2 .select2-selection .select2-selection__rendered {
  display: flex;
  align-items: center;
}

.form .formGroup .formInput .select2 .select2-selection .select2-selection__rendered, .form .formGroup .formInput .select2 .select2-selection .select2-selection__arrow {
  height: 47px;
}

.form .formGroup .formInput .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 100%;
  top: 0;
}

.form .formGroup .formInput .select2-container--default .select2-selection--multiple {
  border: none;
}

.form .formGroup .formInput .select2-container--default .select2-selection--multiple .select2-selection__choice {
  margin-top: 0;
  line-height: 1.7;
  border: none;
  border-radius: 0;
}

.form .formGroup .formInfo {
  margin: 5px 0;
  font-family: "Nunito-Regular";
  font-weight: normal;
  font-size: 12px;
  text-transform: initial;
  letter-spacing: 0;
}

.form .formGroup .formInfo a {
  color: #a37b24;
  text-decoration: none;
}

@media only screen and (max-width: 768px) {
  .form .formGroup {
    flex-direction: column;
    margin: 20px 0;
    min-height: 35px;
    font-size: 10px;
  }
  .form .formGroup .formLabel, .form .formGroup .formInput {
    width: 100%;
  }
  .form .formGroup .formLabel {
    margin-bottom: 10px;
    font-size: 12px;
  }
  .form .formGroup .formInput {
    /* Override Select2 */
  }
  .form .formGroup .formInput .formControl {
    height: 36px;
    font-size: 10px;
  }
  .form .formGroup .formInput .inputGroup .inputGroupPrepend {
    height: 36px;
  }
  .form .formGroup .formInput .passwordInput .togglePassword:before {
    width: 17px;
    height: 17px;
  }
  .form .formGroup .formInput .select2 .select2-selection .select2-selection__rendered, .form .formGroup .formInput .select2 .select2-selection__arrow {
    height: 36px;
  }
  .form .formGroup .formInfo {
    font-size: 10px;
  }
}

.checkboxGroup {
  display: flex;
  flex-wrap: wrap;
}

.checkboxGroup.fullWidth {
  flex-direction: column;
}

.checkboxGroup .checkboxContainer {
  position: relative;
  margin: 0 15px 15px 0;
  padding: 4px 0 0 30px;
  text-transform: uppercase;
  cursor: pointer;
  /* Hide the browser's default checkbox */
  /* Create a custom checkbox */
  /* When the checkbox is checked, change border color */
  /* Create the checkmark/indicator (hidden when not checked) */
  /* Show the checkmark when checked */
  /* Style the checkmark/indicator */
}

.checkboxGroup .checkboxContainer.disabled {
  cursor: not-allowed;
}

.checkboxGroup .checkboxContainer input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.checkboxGroup .checkboxContainer .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border: 1px solid #1D1D1D;
}

.checkboxGroup .checkboxContainer input[type="checkbox"]:checked ~ .checkmark {
  border-color: #a37b24;
}

.checkboxGroup .checkboxContainer .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkboxGroup .checkboxContainer input[type="checkbox"]:checked ~ .checkmark:after {
  display: block;
}

.checkboxGroup .checkboxContainer .checkmark:after {
  top: 2px;
  left: 7px;
  width: 6px;
  height: 12px;
  border: solid #a37b24;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

@media only screen and (max-width: 768px) {
  .checkboxGroup {
    flex-direction: column;
  }
  .checkboxGroup .checkboxContainer {
    margin: 0 0 15px;
    padding: 2px 0 0 25px;
  }
  .checkboxGroup .checkboxContainer .checkmark {
    width: 16px;
    height: 16px;
  }
  .checkboxGroup .checkboxContainer .checkmark:after {
    top: 1px;
    left: 4px;
    width: 4px;
    height: 8px;
  }
}

.radioButtonGroup {
  display: flex;
  flex-wrap: wrap;
}

.radioButtonGroup.fullWidth {
  flex-direction: column;
}

.radioButtonGroup .radioButtonContainer {
  display: inline-block;
  position: relative;
  margin: 0 15px 15px 0;
  padding: 3px 0 0 30px;
  text-transform: uppercase;
  cursor: pointer;
  /* Hide the browser's default radio button */
  /* Create a custom radio button */
  /* Create the checkmark/indicator (hidden when not checked) */
  /* Show the checkmark when checked */
  /* Style the checkmark/indicator */
}

.radioButtonGroup .radioButtonContainer input[type="radio"] {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.radioButtonGroup .radioButtonContainer .checkmark {
  position: absolute;
  top: -1px;
  left: 0;
  height: 24px;
  width: 24px;
  border: 1px solid #1D1D1D;
  border-radius: 50%;
}

.radioButtonGroup .radioButtonContainer .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.radioButtonGroup .radioButtonContainer input[type="radio"]:checked ~ .checkmark:after {
  display: block;
}

.radioButtonGroup .radioButtonContainer .checkmark:after {
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #1D1D1D;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 768px) {
  .radioButtonGroup {
    flex-direction: column;
  }
  .radioButtonGroup .radioButtonContainer {
    padding: 3px 0 0 25px;
  }
  .radioButtonGroup .radioButtonContainer .checkmark {
    width: 20px;
    height: 20px;
  }
  .radioButtonGroup .radioButtonContainer .checkmark:after {
    width: 13px;
    height: 13px;
  }
}

.productCategoryGroup {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.productCategoryGroup .productCategoryContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 20%;
  max-width: 140px;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  text-align: center;
  border: 1px solid #1D1D1D;
  cursor: pointer;
}

.productCategoryGroup .productCategoryContainer .iconWrapper {
  width: 28px;
  margin-bottom: 10px;
}

.productCategoryGroup .productCategoryContainer .iconWrapper img {
  width: 100%;
}

.productCategoryGroup .productCategoryContainer .iconWrapper img.whenActiveIcon {
  display: none;
}

.productCategoryGroup .productCategoryContainer .iconWrapper.active img {
  display: none;
}

.productCategoryGroup .productCategoryContainer .iconWrapper.active img.whenActiveIcon {
  display: inherit;
}

@media only screen and (max-width: 768px) {
  .productCategoryGroup .productCategoryContainer {
    flex-direction: row;
    justify-content: center;
    width: 48%;
    max-width: unset;
    margin-bottom: 15px;
    font-size: 10px;
  }
  .productCategoryGroup .productCategoryContainer .iconWrapper {
    width: 22px;
    margin: 0 10px 0 0;
  }
}

.select2-container .select2-dropdown.address-area-codes-dropdown {
  width: max-content !important;
}

.select2-container .select2-dropdown.address-area-codes-dropdown .select2-results .select2-results__options .select2-results__option {
  display: flex;
  align-items: center;
  padding: 10px 14px;
  font-family: "Nunito-Bold";
  font-size: 14px;
}

.select2-container .select2-dropdown.address-area-codes-dropdown .select2-results .select2-results__options .select2-results__option span {
  font-family: "Nunito-Bold";
  font-size: 14px;
}

.select2-container .select2-dropdown.address-area-codes-dropdown .select2-results .select2-results__options .select2-results__option .optionImage {
  width: 25px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
}

.select2-container .select2-dropdown.address-area-codes-dropdown .select2-results .select2-results__options .select2-results__option .optionImage img {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .select2-container .select2-dropdown.address-area-codes-dropdown .select2-results .select2-results__options .select2-results__option {
    padding: 5px 14px;
    font-size: 10px;
  }
  .select2-container .select2-dropdown.address-area-codes-dropdown .select2-results .select2-results__options .select2-results__option .optionImage {
    width: 22px;
  }
  .select2-container .select2-dropdown.address-area-codes-dropdown .select2-results .select2-results__options .select2-results__option span {
    font-size: 10px;
  }
}

.button {
  display: inline-block;
  margin: 20px 0;
  padding: 0 30px;
  font-family: "Nunito-Bold";
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  line-height: 2.5;
  letter-spacing: 0;
  white-space: nowrap;
  /* Safari adjustment */
}

.button:disabled {
  opacity: .5;
  cursor: not-allowed;
  color: #adadad !important;
  background-color: #787878 !important;
  border: 1px solid #787878 !important;
}

.button-small {
  font-size: 12px;
}

.button-right {
  float: right;
}

.button-full {
  width: 100%;
}

.button-center {
  display: block;
  margin: 20px auto;
}

.button-big {
  min-width: 320px;
  max-width: max-content;
}

.button-no-margin {
  margin: 0 !important;
}

.button-auto-width {
  max-width: max-content !important;
}

@media only screen and (max-width: 768px) {
  .button {
    display: block;
    width: 100%;
    margin: 20px 0 5px;
    padding: 5px 10px;
    font-size: 12px;
  }
  .button-small {
    padding: 0;
    font-size: 10px;
  }
  .button-right {
    margin: 5px 0 20px;
  }
  .button-big {
    min-width: 100%;
  }
}

.button-link {
  font-family: "Nunito-ExtraBold";
  text-decoration: none;
  border: none;
  background: none;
  color: #1D1D1D;
}

.button-link:hover {
  background: none;
  text-decoration: underline;
}

.button-primary {
  background-color: #1D1D1D;
  border: 1px solid #1D1D1D;
  color: #fff;
}

.button-primary:hover {
  background-color: #fff;
  color: #1D1D1D;
}

.button-primary-faded {
  background-color: #fff;
  border: 1px solid #1D1D1D;
  color: #1D1D1D;
}

.button-primary-faded:hover {
  background-color: #1D1D1D;
  color: #fff;
}

.button-primary-faded.button-no-hover:hover {
  background-color: #fff;
  color: #1D1D1D;
}

.button-secondary {
  background-color: #e3e3e3;
  border: 1px solid #e3e3e3;
  color: #1D1D1D;
}

.button-secondary:hover {
  background-color: #adadad;
}

.button-gold {
  background-color: #a37b24;
  border: 1px solid #a37b24;
  color: #fff;
}

.button-gold:hover {
  background-color: #fff;
  color: #a37b24;
}

.button-gold-faded {
  background-color: rgba(163, 123, 36, 0.1);
  border: 1px solid #a37b24;
  color: #a37b24;
}

.button-gold-faded:hover {
  background-color: #a37b24;
  color: #fff;
}

.button-white {
  box-shadow: 0 3px 5px 1px #e3e3e3;
  background-color: #fff;
  border: 1px solid #fff;
  color: #1D1D1D;
}

.button-white:hover {
  background-color: #e3e3e3;
  border-color: #e3e3e3;
}

.button:after {
  content: "";
  display: block;
  clear: both;
}

.button {
  display: inline-block;
  margin: 20px 0;
  padding: 0 30px;
  font-family: "Nunito-Bold";
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  line-height: 2.5;
  letter-spacing: 0;
  white-space: nowrap;
  /* Safari adjustment */
}

.button:disabled {
  opacity: .5;
  cursor: not-allowed;
  color: #adadad !important;
  background-color: #787878 !important;
  border: 1px solid #787878 !important;
}

.button-small {
  font-size: 12px;
}

.button-right {
  float: right;
}

.button-full {
  width: 100%;
}

.button-center {
  display: block;
  margin: 20px auto;
}

.button-big {
  min-width: 320px;
  max-width: max-content;
}

.button-no-margin {
  margin: 0 !important;
}

.button-auto-width {
  max-width: max-content !important;
}

@media only screen and (max-width: 768px) {
  .button {
    display: block;
    width: 100%;
    margin: 20px 0 5px;
    padding: 5px 10px;
    font-size: 12px;
  }
  .button-small {
    padding: 0;
    font-size: 10px;
  }
  .button-right {
    margin: 5px 0 20px;
  }
  .button-big {
    min-width: 100%;
  }
}

.button-link {
  font-family: "Nunito-ExtraBold";
  text-decoration: none;
  border: none;
  background: none;
  color: #1D1D1D;
}

.button-link:hover {
  background: none;
  text-decoration: underline;
}

.button-primary {
  background-color: #1D1D1D;
  border: 1px solid #1D1D1D;
  color: #fff;
}

.button-primary:hover {
  background-color: #fff;
  color: #1D1D1D;
}

.button-primary-faded {
  background-color: #fff;
  border: 1px solid #1D1D1D;
  color: #1D1D1D;
}

.button-primary-faded:hover {
  background-color: #1D1D1D;
  color: #fff;
}

.button-primary-faded.button-no-hover:hover {
  background-color: #fff;
  color: #1D1D1D;
}

.button-secondary {
  background-color: #e3e3e3;
  border: 1px solid #e3e3e3;
  color: #1D1D1D;
}

.button-secondary:hover {
  background-color: #adadad;
}

.button-gold {
  background-color: #a37b24;
  border: 1px solid #a37b24;
  color: #fff;
}

.button-gold:hover {
  background-color: #fff;
  color: #a37b24;
}

.button-gold-faded {
  background-color: rgba(163, 123, 36, 0.1);
  border: 1px solid #a37b24;
  color: #a37b24;
}

.button-gold-faded:hover {
  background-color: #a37b24;
  color: #fff;
}

.button-white {
  box-shadow: 0 3px 5px 1px #e3e3e3;
  background-color: #fff;
  border: 1px solid #fff;
  color: #1D1D1D;
}

.button-white:hover {
  background-color: #e3e3e3;
  border-color: #e3e3e3;
}

.button:after {
  content: "";
  display: block;
  clear: both;
}

.popupBox {
  position: relative;
  top: 50%;
  display: none;
  width: 600px;
  margin: 0 auto;
  transform: translateY(-50%);
}

.popupBox .popupContent {
  position: relative;
  max-height: 800px;
  padding: 0;
  background-color: #fff;
  box-shadow: none;
  overflow-y: auto;
}

.popupBox .popupContent .title {
  position: sticky;
  top: 0;
  left: 0;
  padding: 20px;
  font-family: "Nunito-Bold";
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #1D1D1D;
  background-color: #fff;
  box-shadow: 0 0 6px rgba(29, 29, 29, 0.2);
  border-bottom: none;
  z-index: 2;
}

.popupBox .popupContent p {
  margin-bottom: 0;
  padding: 0;
  font-size: 14px;
  color: #1D1D1D;
}

.popupBox .popupContent .contentWrapper {
  padding: 20px !important;
  font-size: 14px;
}

.popupBox .popupContent .contentWrapper.textCenter {
  text-align: center;
}

.popupBox .popupContent .contentWrapper .form {
  padding: 0;
}

.popupBox .popupContent .actionWrapper {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 6px rgba(29, 29, 29, 0.2);
  z-index: 2;
}

.popupBox .popupContent .actionWrapper .button {
  margin: 0;
}

.popupBox .popupContent .closeBtn {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
  z-index: 2;
}

.popupBox .popupContent .closeBtn img {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .popupBox {
    width: 100%;
    max-width: 550px;
    padding: 15px;
  }
  .popupBox .popupContent {
    max-height: calc(100vh - 30px);
  }
  .popupBox .popupContent .title {
    padding: 15px 35px;
    font-size: 16px;
    line-height: 18px;
  }
  .popupBox .popupContent .contentWrapper,
  .popupBox .popupContent .button,
  .popupBox .popupContent p, .popupBox .popupContent span {
    font-size: 12px;
  }
  .popupBox .popupContent .closeBtn {
    top: 18px;
    width: 14px;
    height: 14px;
  }
}

@media only screen and (max-width: 320px) {
  .popupBox .popupContent .title {
    font-size: 12px;
    line-height: 14px;
  }
  .popupBox .popupContent .contentWrapper,
  .popupBox .popupContent .button,
  .popupBox .popupContent p, .popupBox .popupContent span {
    font-size: 10px;
  }
  .popupBox .popupContent .closeBtn {
    top: 14px;
  }
}

/* Popups */
#packaging-example-popup .contentWrapper .imageWrapper {
  width: 100%;
}

#packaging-example-popup .contentWrapper .imageWrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#popup-condition-guide .popupContent {
  padding: 0;
}

#popup-condition-guide .popupContent .title {
  border-bottom: 1px solid #C4C4C4;
}

#popup-condition-guide .popupContent .contentWrapper .conditionGuide {
  margin-bottom: 20px;
}

#popup-condition-guide .popupContent .contentWrapper .conditionGuide h5 {
  padding: 0;
  margin-bottom: 10px;
  font-family: "Nunito-ExtraBold";
  font-size: 18px;
  letter-spacing: 0.1em;
  color: #1D1D1D;
}

#popup-condition-guide .popupContent .contentWrapper .conditionGuide p {
  font-family: "Nunito-Regular";
  font-size: 14px;
  letter-spacing: 0.1em;
  color: #1D1D1D;
}

@media only screen and (max-width: 768px) {
  #popup-condition-guide .popupContent .title {
    padding: 15px;
    font-size: 16px;
    text-align: center;
  }
  #popup-condition-guide .popupContent .contentWrapper .conditionGuide h5 {
    font-size: 14px;
  }
  #popup-condition-guide .popupContent .contentWrapper .conditionGuide p {
    font-size: 12px;
  }
}

#country-popup .popupContent .contentWrapper .countryFlag {
  width: 40px;
  margin: 0 auto 15px;
}

#country-popup .popupContent .contentWrapper .countryFlag img {
  width: 100%;
}

#blocked-account-popup .popupContent .contentWrapper {
  padding: 40px !important;
}

#blocked-account-popup .popupContent .contentWrapper .iconWrapper {
  width: 120px;
  margin: 0 auto 20px;
}

#blocked-account-popup .popupContent .contentWrapper .iconWrapper img {
  width: 100%;
}

#blocked-account-popup .popupContent .contentWrapper a.link {
  font-family: "Nunito-ExtraBold";
}

@media only screen and (max-width: 640px) {
  #blocked-account-popup .popupContent .contentWrapper {
    padding: 20px !important;
  }
  #blocked-account-popup .popupContent .contentWrapper .iconWrapper {
    width: 65px;
  }
}

/* Create mixin for all repetitive style block gradually */
/* GLOBAL */
.tabWrapper {
  display: flex;
  font-family: "Nunito-Bold";
  font-size: 16px;
  text-transform: uppercase;
  color: #000;
}

.tabWrapper .tab {
  flex-grow: 1;
  padding: 10px 0;
  box-sizing: border-box;
  text-align: center;
  letter-spacing: 2px;
}

.tabWrapper .tab a {
  color: #1D1D1D;
}

.tabWrapper .tab:hover {
  border-bottom: 5px solid #a37b24;
}

.tabWrapper .tab--active {
  border-bottom: 5px solid #a37b24;
}

.tabWrapper .tab--active a {
  font-weight: bold;
  color: #a37b2c;
}

@media only screen and (max-width: 768px) {
  .tabWrapper {
    overflow-x: auto;
    font-size: 12px;
  }
  .tabWrapper .tab {
    padding: 10px;
  }
}

.actionIcon {
  position: relative;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.actionIcon:before {
  content: '\2807';
  position: absolute;
  left: 15px;
  z-index: 1;
  font-size: 22px;
  color: #231F20;
}

.actionIcon:hover .actionWrapper {
  display: block;
}

.actionIcon .actionWrapper {
  display: none;
  position: absolute;
  top: 30px;
  right: 0;
  z-index: 2;
  width: max-content;
  background: #fff;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);
}

.actionIcon .actionWrapper a, .actionIcon .actionWrapper button {
  display: block;
  width: 100%;
  margin: 15px;
  background: none;
  font-family: "Nunito-ExtraBold";
  font-size: 12px;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
  color: #1D1D1D;
  border-bottom: 1px solid #9B9B9B;
  transition: .3s;
}

.actionIcon .actionWrapper a:last-child, .actionIcon .actionWrapper button:last-child {
  border-bottom: none;
}

.statusBox {
  display: inline-block;
  margin-bottom: 5px;
  padding: 6px 10px;
  border-radius: 25px;
  font-family: "Nunito-Bold";
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  text-align: center;
}

.statusBox.deliveryType, .statusBox.pending {
  background-color: #e3e3e3;
  color: #000;
}

.statusBox.status-1, .statusBox.status-3, .statusBox.status-4, .statusBox.needEdit, .statusBox.delisted {
  background-color: #F7941D;
  color: #fff;
}

.statusBox.status-2, .statusBox.status-6, .statusBox.active, .statusBox.listed {
  background-color: #4F9A76;
  color: #fff;
}

.statusBox.status-5, .statusBox.status-8 {
  background-color: #a37b24;
  color: #fff;
}

.statusBox.status-7, .statusBox.status-10, .statusBox.inactive, .statusBox.sold, .statusBox.rejected {
  background-color: #76150C;
  color: #fff;
}

.statusBox.status-11, .statusBox.status-12, .statusBox.general {
  background-color: #231F20;
  color: #fff;
}

.flexor {
  display: flex;
}

.flexor-wrap {
  flex-wrap: wrap;
}

.flexor-row {
  flex-direction: row;
}

.flexor-column {
  flex-direction: column;
}

.flexor-between {
  justify-content: space-between;
}

.flexor-around {
  justify-content: space-around;
}

.flexor .flexorItem-20 {
  width: 20%;
}

.flexor .flexorItem-25 {
  width: 25%;
}

.flexor .flexorItem-30 {
  width: 30%;
}

.flexor .flexorItem-33 {
  width: 33.333333%;
}

.flexor .flexorItem-40 {
  width: 40%;
}

.flexor .flexorItem-50 {
  width: 50%;
}

/* SINGLE */
.fullOptionWrapper {
  font-family: "Nunito-Regular";
  font-size: 14px;
  letter-spacing: 0.1em;
}

.fullOptionWrapper .sellingWrapper {
  display: flex;
  justify-content: space-between;
}

.fullOptionWrapper .sellingWrapper .option {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 48%;
  border: 1px solid #999999;
  padding: 25px 20px;
  color: #1D1D1D;
}

.fullOptionWrapper .sellingWrapper .option .optionIcon {
  width: 80px;
  height: 80px;
  margin-bottom: 30px;
}

.fullOptionWrapper .sellingWrapper .option .optionIcon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.fullOptionWrapper .preliminaryWrapper {
  width: 100%;
  margin: 50px 0;
  text-align: center;
  color: #1D1D1D;
}

.fullOptionWrapper .preliminaryWrapper a {
  display: block;
  width: 120px;
  margin: 15px auto;
  padding: 0;
  font-family: "Nunito-Bold";
}

.fullOptionWrapper .optionTitle {
  margin-bottom: 14px;
  font-family: "Nunito-Bold";
  font-size: 14px;
  text-transform: uppercase;
}

.fullOptionWrapper .optionTitle span {
  font-family: "Nunito-Bold";
  font-size: 12px;
}

@media only screen and (min-width: 1200px) {
  .fullOptionWrapper {
    font-size: 14px;
  }
  .fullOptionWrapper .sellingWrapper .option {
    padding: 40px 30px;
  }
  .fullOptionWrapper .optionTitle {
    font-size: 18px;
  }
  .fullOptionWrapper .optionTitle span {
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {
  .fullOptionWrapper {
    margin: 10px;
  }
}

@media only screen and (max-width: 480px) {
  .fullOptionWrapper .sellingWrapper {
    flex-direction: column;
  }
  .fullOptionWrapper .sellingWrapper .option {
    width: 100%;
    margin: 0 0 10px;
  }
  .fullOptionWrapper .preliminaryWrapper {
    margin: 30px 0;
  }
}

.accessNotAllowedContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 750px;
  min-height: 600px;
  margin: 0 auto;
  text-align: center;
  color: #1D1D1D;
}

.accessNotAllowedContainer .iconWrapper {
  width: 120px;
  margin: 0 auto;
}

.accessNotAllowedContainer .iconWrapper img {
  width: 100%;
}

.accessNotAllowedContainer h2 {
  margin: 25px 0;
  font-family: "Nunito-ExtraBold";
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #1D1D1D;
}

.accessNotAllowedContainer p {
  font-size: 14px;
  line-height: 20px;
}

@media only screen and (max-width: 768px) {
  .accessNotAllowedContainer {
    padding: 15px;
  }
  .accessNotAllowedContainer .iconWrapper {
    width: 65px;
  }
  .accessNotAllowedContainer h2 {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 1.4px;
  }
  .accessNotAllowedContainer p {
    font-size: 12px;
  }
}

.productPreviewWrapper .productPreviewContainer {
  display: flex;
  justify-content: space-between;
  min-height: 700px;
}

.productPreviewWrapper .productPreviewContainer .productImageWrapper {
  position: relative;
  width: 40%;
  max-width: 260px;
  margin-right: 40px;
}

.productPreviewWrapper .productPreviewContainer .productImageWrapper .bigPhoto {
  position: relative;
  width: 100%;
  height: 260px;
  border: 1px solid #E5E5E5;
}

.productPreviewWrapper .productPreviewContainer .productImageWrapper .photoThumbnails {
  margin: 15px 0 0 -22px;
  width: 314px;
  height: 60px;
  box-sizing: border-box;
}

.productPreviewWrapper .productPreviewContainer .productImageWrapper .photoThumbnails .nav {
  position: relative;
  width: 12px;
  height: 100%;
  cursor: pointer;
}

.productPreviewWrapper .productPreviewContainer .productImageWrapper .photoThumbnails .nav.left {
  float: left;
  margin-right: 10px;
}

.productPreviewWrapper .productPreviewContainer .productImageWrapper .photoThumbnails .nav.right {
  float: right;
  margin-left: 10px;
}

.productPreviewWrapper .productPreviewContainer .productImageWrapper .photoThumbnails .nav img.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}

.productPreviewWrapper .productPreviewContainer .productImageWrapper .photoThumbnails .thumbnails {
  overflow: hidden;
  white-space: nowrap;
  width: 260px;
  height: 100%;
  float: left;
}

.productPreviewWrapper .productPreviewContainer .productImageWrapper .photoThumbnails .thumbnails .thumbnail {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 100%;
  margin-right: 15px;
  box-sizing: border-box;
  border: 1px solid #E5E5E5;
  cursor: pointer;
}

.productPreviewWrapper .productPreviewContainer .productImageWrapper .photoThumbnails .thumbnails .thumbnail:last-child {
  margin-right: 0;
}

.productPreviewWrapper .productPreviewContainer .productImageWrapper .photoThumbnails .thumbnails .thumbnail:hover, .productPreviewWrapper .productPreviewContainer .productImageWrapper .photoThumbnails .thumbnails .thumbnail.active {
  border: 1px solid #1D1D1D;
}

.productPreviewWrapper .productPreviewContainer .productImageWrapper .photoThumbnails:after {
  content: '';
  display: block;
  clear: both;
}

.productPreviewWrapper .productPreviewContainer .productImageWrapper .bigPhoto img,
.productPreviewWrapper .productPreviewContainer .productImageWrapper .photoThumbnails .thumbnails .thumbnail img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  margin: auto;
}

.productPreviewWrapper .productPreviewContainer .productImageWrapper .bigPhoto img.placeholderImage,
.productPreviewWrapper .productPreviewContainer .productImageWrapper .photoThumbnails .thumbnails .thumbnail img.placeholderImage {
  opacity: .5;
}

.productPreviewWrapper .productPreviewContainer .productInfoWrapper {
  width: 60%;
  font-family: "Nunito-Regular";
  color: #1D1D1D;
}

.productPreviewWrapper .productPreviewContainer .productInfoWrapper .info h4 {
  font-family: "Nunito-Bold";
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 2;
}

.productPreviewWrapper .productPreviewContainer .productInfoWrapper .info.productDesigner, .productPreviewWrapper .productPreviewContainer .productInfoWrapper .info.productStyle {
  font-size: 18px;
}

.productPreviewWrapper .productPreviewContainer .productInfoWrapper .info.productDesigner {
  font-family: "Nunito-Bold";
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.productPreviewWrapper .productPreviewContainer .productInfoWrapper .info.productStyle {
  line-height: 1.5;
}

.productPreviewWrapper .productPreviewContainer .productInfoWrapper .info.productDetail, .productPreviewWrapper .productPreviewContainer .productInfoWrapper .info.productPrice, .productPreviewWrapper .productPreviewContainer .productInfoWrapper .info.additionalNotes, .productPreviewWrapper .productPreviewContainer .productInfoWrapper .info.warningBlock {
  padding: 25px 0;
}

.productPreviewWrapper .productPreviewContainer .productInfoWrapper .info.productDetail {
  overflow-x: auto;
  border-bottom: 1px solid #E5E5E5;
}

.productPreviewWrapper .productPreviewContainer .productInfoWrapper .info.productDetail table {
  margin-top: 10px;
  font-family: "Nunito-Regular";
  font-size: 12px;
}

.productPreviewWrapper .productPreviewContainer .productInfoWrapper .info.productDetail table tr td {
  padding: 3px 0;
}

.productPreviewWrapper .productPreviewContainer .productInfoWrapper .info.productDetail table tr td:first-child {
  width: 115px;
}

.productPreviewWrapper .productPreviewContainer .productInfoWrapper .info.productPrice {
  display: flex;
  justify-content: space-between;
}

.productPreviewWrapper .productPreviewContainer .productInfoWrapper .info.productPrice .price, .productPreviewWrapper .productPreviewContainer .productInfoWrapper .info.productPrice .earnings {
  width: 45%;
}

.productPreviewWrapper .productPreviewContainer .productInfoWrapper .info.productPrice .price h5, .productPreviewWrapper .productPreviewContainer .productInfoWrapper .info.productPrice .earnings h5 {
  padding-bottom: 5px;
  font-family: "Nunito-Bold";
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #a37b24;
}

.productPreviewWrapper .productPreviewContainer .productInfoWrapper .info.additionalNotes, .productPreviewWrapper .productPreviewContainer .productInfoWrapper .info.warningBlock {
  font-size: 12px;
}

.productPreviewWrapper .productPreviewContainer .productInfoWrapper .info.additionalNotes {
  padding: 0 0 25px 0;
}

.productPreviewWrapper .productPreviewContainer .productInfoWrapper .info.warningBlock {
  color: #a37b24;
  border-top: 1px solid #E5E5E5;
}

.productPreviewWrapper .productPreviewContainer .productInfoWrapper .info.warningBlock p {
  padding-bottom: 10px;
}

@media only screen and (min-width: 1281px) {
  .productPreviewWrapper .productPreviewContainer .productImageWrapper {
    max-width: 320px;
  }
  .productPreviewWrapper .productPreviewContainer .productImageWrapper .bigPhoto {
    height: 320px;
  }
  .productPreviewWrapper .productPreviewContainer .productImageWrapper .photoThumbnails {
    width: 364px;
  }
  .productPreviewWrapper .productPreviewContainer .productImageWrapper .photoThumbnails .thumbnails {
    width: 320px;
  }
}

@media only screen and (max-width: 959px) {
  .productPreviewWrapper .productPreviewContainer {
    min-height: 0;
    padding-bottom: 40px;
  }
  .productPreviewWrapper .productPreviewContainer .productImageWrapper {
    max-width: 300px;
    margin-right: 20px;
  }
  .productPreviewWrapper .productPreviewContainer .productImageWrapper .bigPhoto {
    height: 300px;
  }
  .productPreviewWrapper .productPreviewContainer .productImageWrapper .photoThumbnails {
    width: 100%;
    margin: 15px 0 0;
  }
  .productPreviewWrapper .productPreviewContainer .productImageWrapper .photoThumbnails .nav {
    display: none;
  }
  .productPreviewWrapper .productPreviewContainer .productImageWrapper .photoThumbnails .thumbnails {
    width: 100%;
    overflow-x: auto;
  }
  .productPreviewWrapper .productPreviewContainer .productImageWrapper .photoThumbnails .thumbnails .thumbnail {
    margin-right: 10px;
  }
  .productPreviewWrapper .productPreviewContainer .productInfoWrapper .info.productDesigner, .productPreviewWrapper .productPreviewContainer .productInfoWrapper .info.productStyle {
    font-size: 14px;
  }
  .productPreviewWrapper .productPreviewContainer .productInfoWrapper .info.productDetail, .productPreviewWrapper .productPreviewContainer .productInfoWrapper .info.productPrice, .productPreviewWrapper .productPreviewContainer .productInfoWrapper .info.additionalNotes, .productPreviewWrapper .productPreviewContainer .productInfoWrapper .info.warningBlock {
    padding: 15px 0;
  }
  .productPreviewWrapper .productPreviewContainer .productInfoWrapper .info.productPrice .price h5, .productPreviewWrapper .productPreviewContainer .productInfoWrapper .info.productPrice .earnings h5 {
    font-size: 14px;
  }
  .productPreviewWrapper .productPreviewContainer .productInfoWrapper .info.additionalNotes {
    padding: 0 0 15px 0;
  }
  .productPreviewWrapper .productPreviewContainer .productInfoWrapper .info.warningBlock {
    font-size: 10px;
  }
}

@media only screen and (max-width: 640px) {
  .productPreviewWrapper .productPreviewContainer {
    flex-direction: column;
  }
  .productPreviewWrapper .productPreviewContainer .productImageWrapper, .productPreviewWrapper .productPreviewContainer .productInfoWrapper {
    width: 100%;
    max-width: 100%;
  }
  .productPreviewWrapper .productPreviewContainer .productImageWrapper {
    margin: 0 0 25px;
  }
  .productPreviewWrapper .productPreviewContainer .productInfoWrapper .info.productPrice {
    flex-direction: column;
    height: auto;
  }
  .productPreviewWrapper .productPreviewContainer .productInfoWrapper .info.productPrice .price, .productPreviewWrapper .productPreviewContainer .productInfoWrapper .info.productPrice .earnings {
    width: 100%;
  }
  .productPreviewWrapper .productPreviewContainer .productInfoWrapper .info.productPrice .price {
    margin-bottom: 15px;
  }
  .productPreviewWrapper .productPreviewContainer .productInfoWrapper .info.productPrice .price p {
    margin-top: 0;
  }
}

.locationInformation {
  display: flex;
  margin: 40px 0;
  padding: 20px 0;
  background-color: rgba(163, 123, 36, 0.1);
  font-family: "Nunito-Regular";
  font-size: 14px;
  border: 1px solid #a37b24;
  border-radius: 4px;
}

.locationInformation.fullWidth {
  display: block;
  padding: 20px;
}

.locationInformation .leftContent, .locationInformation .rightContent {
  flex: 1 1 50%;
  padding: 0 20px;
}

.locationInformation .leftContent {
  border-right: 1px solid #9B9B9B;
}

.locationInformation .exampleButton {
  font-family: "Nunito-Bold";
  color: #a37b24;
}

@media only screen and (max-width: 768px) {
  .locationInformation {
    flex-direction: column;
    margin: 20px 10px;
    padding: 0 12px;
    font-size: 12px;
    text-align: left;
  }
  .locationInformation.fullWidth {
    padding: 12px;
  }
  .locationInformation .leftContent, .locationInformation .rightContent {
    padding: 20px 0;
  }
  .locationInformation .leftContent {
    border-right: none;
    border-bottom: 1px solid #9B9B9B;
  }
}

.footerAction {
  margin-top: 20px;
  border: 1px solid #adadad;
  padding: 10px;
}

/* OVERRIDE */
.pagingBox {
  position: relative;
  height: 50px;
  margin: 40px 0 20px;
  background: #fff;
}

.pagingBox ul {
  padding-top: 10px;
  font-family: "Nunito-Bold";
  font-size: 14px;
  text-align: center;
}

.pagingBox ul li {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 0 3px;
  line-height: 28px;
}

.pagingBox ul li:hover, .pagingBox ul li.active {
  background-color: #1D1D1D;
}

.pagingBox ul li:hover a, .pagingBox ul li:hover label, .pagingBox ul li:hover span, .pagingBox ul li.active a, .pagingBox ul li.active label, .pagingBox ul li.active span {
  color: #fff;
}

.pagingBox ul li.normal, .pagingBox ul li a, .pagingBox ul li label, .pagingBox ul li span {
  /* Unset Inherited Style */
  width: unset;
  height: unset;
  line-height: unset;
  border-radius: unset;
  display: unset;
  margin: unset;
  /* End Unset*/
  color: #1D1D1D;
}

.pagingBox ul li a.normal:hover, .pagingBox ul li a.selected, .pagingBox ul li a:hover, .pagingBox ul li.active span {
  background-color: transparent;
  font-family: "Nunito-Bold" !important;
  color: #fff;
}

/* Member Filter Combobox */
.topAction {
  position: absolute;
  top: 20px;
  right: 0;
  display: flex;
  justify-content: flex-end;
}

.topAction .customSelectBox {
  width: 220px;
  margin-left: 10px;
  border: 1px solid #1D1D1D;
  line-height: 4;
}

.topAction .customSelectBox span {
  font-family: "Nunito-Bold";
  font-size: 14px;
  letter-spacing: 1.2px;
  color: #1D1D1D;
}

.topAction .customSelectBox .select2-selection__rendered,
.topAction .customSelectBox .select2-selection__arrow {
  height: 100%;
  font-family: "Nunito-Bold";
  font-weight: bold;
  font-size: 14px;
  line-height: 26px;
  text-transform: uppercase;
  color: #1D1D1D;
}

.topAction .customSelectBox .select2-selection__arrow {
  top: 0;
}

@media only screen and (max-width: 959px) {
  .topAction {
    position: static;
    padding: 10px;
  }
  .topAction .customSelectBox {
    line-height: 3;
  }
  .topAction .customSelectBox span,
  .topAction .customSelectBox .select2-selection__rendered,
  .topAction .customSelectBox .select2-selection__arrow {
    font-size: 12px;
  }
}

@media only screen and (max-width: 480px) {
  .topAction {
    flex-direction: column;
  }
  .topAction .customSelectBox {
    width: 100%;
    margin: 0 0 10px 0;
  }
}

/* Tabs */
.tabsWrapper {
  display: flex;
}

.tabsWrapper .tab {
  width: 50%;
  padding: 15px 5px;
  font-family: "Nunito-Bold";
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  line-height: 22px;
  letter-spacing: 0.1em;
  color: #1D1D1D;
  border-bottom: 4px solid #c4c4c4;
}

.tabsWrapper .tab:hover, .tabsWrapper .tab.active {
  border-color: #1D1D1D;
}

@media only screen and (max-width: 768px) {
  .tabsWrapper .tab {
    padding: 10px 5px;
    font-size: 12px;
    line-height: 14px;
  }
}

/* FIXER */
.profileWrapper h3 {
  margin: 16px 0;
}

@media only screen and (max-width: 768px) {
  span.mobile {
    padding-bottom: 3px;
    font-size: 10px;
    color: #4A4A4A !important;
  }
}

/* Create mixin for all repetitive style block gradually */
.authContainer {
  max-width: 380px;
  margin: auto;
}

.authContainer.otpPageContainer {
  max-width: 760px;
  text-align: center;
}

.authContainer h2 {
  font-family: "Nunito-Bold";
  font-size: 24px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #1D1D1D;
}

@media only screen and (max-width: 769px) {
  .authContainer h2 {
    font-size: 18px;
  }
}

.authContainer .authPageDescription {
  padding: 5px 0;
  line-height: 20px;
  font-family: "Nunito-Regular";
  font-size: 12px;
  text-align: center;
  color: #1D1D1D;
}

.authContainer .authPageDescription.otpPageDescription {
  margin-top: 25px;
}

.authContainer .authWrapper {
  padding: 40px 0;
}

@media only screen and (max-width: 769px) {
  .authContainer .authWrapper {
    padding: 15px;
  }
}

.authContainer .authWrapper .authContent .form {
  margin-top: 30px;
}

@media only screen and (max-width: 769px) {
  .authContainer .authWrapper .authContent .form {
    padding: 0;
  }
}

.authContainer .authWrapper .authContent .form .forgotPassword {
  font-family: "Nunito-Bold";
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #1D1D1D;
  float: right;
}

.authContainer .authWrapper .authContent .form .forgotPassword:after {
  content: "";
  display: block;
  clear: both;
}

@media only screen and (max-width: 769px) {
  .authContainer .authWrapper .authContent .form .forgotPassword {
    margin-bottom: 25px;
  }
}

.authContainer .authWrapper .authContent .form .checkboxContainer {
  padding: 0 0 0 35px;
  font-size: 10px;
  text-transform: initial;
}

.authContainer .authWrapper .authContent .form .checkboxContainer a {
  text-decoration: underline;
}

@media only screen and (max-width: 768px) {
  .authContainer .authWrapper .authContent .form .checkboxContainer {
    padding: 0 0 0 25px;
  }
}

.authContainer .authWrapper .authContent .bottomBox {
  margin-top: 5px;
  font-family: "Nunito-Regular";
  font-size: 12px;
  text-align: center;
  color: #1D1D1D;
}

.authContainer .authWrapper .authContent .bottomBox a {
  font-family: "Nunito-Bold";
  text-transform: uppercase;
  color: #1D1D1D !important;
}

@media only screen and (max-width: 769px) {
  .authContainer .authWrapper .authContent .bottomBox {
    margin-top: 25px;
  }
}

.otpForm {
  margin-top: 20px;
}

.otpForm input[type="text"] {
  display: inline-block;
  width: 50px;
  height: 50px;
  margin: 0 8px;
  font-family: "Nunito-ExtraBold";
  font-size: 22px;
  text-align: center;
  border-bottom: 2px solid #C4C4C4;
  color: #1D1D1D;
}

.otpForm input[type="text"]:focus {
  border-color: #a37b24;
}

.otpForm .otpAction .button.button-link {
  display: inline-block;
  padding: 0 10px;
  width: max-content;
  font-family: "Nunito-ExtraBold";
  font-size: 14px;
  color: #c4c4c4;
}

.otpForm .otpAction .button.button-link.active {
  color: #1D1D1D;
}

.otpForm .otpAction .button.button-link:hover {
  text-decoration: none;
}

.otpForm .otpAction #otp-countdown {
  font-size: 14px;
}

@media only screen and (max-width: 768px) {
  .otpForm input[type="text"] {
    width: 35px;
    height: 35px;
    font-size: 18px;
  }
  .otpForm .otpAction .button.button-link {
    font-size: 12px;
  }
}
