@import '../../_variables.scss';

.form {
    color: $black-v2;

    &:after {
        content: "";
        display: block;
        clear: both;
    }

    @media only screen and (max-width: $screen-tablet-max) {
        padding: 0 $gutter-md;
    }

    .selectedItem {
        color: $gold-dark !important;
        border-color: $gold-dark !important;
    }

    .threeQuarterBox {
        display: flex;
        justify-content: space-between;

        .firstWrapper {
            width: 60%;
            margin-right: $gutter-md;
        }

        .secondWrapper {
            width: 40%;
        }

        @media only screen and (max-width: $screen-phone-max) {
            flex-direction: column;

            .firstWrapper,
            .secondWrapper {
                width: 100%;
                max-width: 100%;
                margin-right: 0;
            }
        }
    }

    .formSectionHeading {
        margin: $gutter-lg 0;
        font-family: $font-sub-heading;
        font-weight: bold;
        font-size: $font-size-xl;
        color: $black-v2;
        text-transform: uppercase;
        letter-spacing: 0.1em;

        @media only screen and (max-width: $screen-tablet-max) {
            margin: $gutter-md 0;
            font-size: $font-size-md;
        }
    }

    label {
        cursor: pointer;
    }

    .formGroup {
        position: relative;
        display: flex;
        min-height: 55px;
        margin: 30px 0;
        font-family: $font-regular-2nd;
        font-size: $font-size-md;

        &.nonLabel {
            .formLabel {
                display: none;
            }

            .formInput {
                width: 100%;
            }
        }

        &.fullWidth {
            flex-direction: column;

            .formLabel, .formInput {
                width: 100%;
            }

            .formLabel {
                margin-bottom: $gutter-md;
                padding-right: 0;
            }
        }

        .formLabel {
            width: 30%;
            padding-right: $gutter-lg;
            font-family: $font-sub-heading;
            font-weight: bold;
            font-size: $font-size-md;
            text-transform: uppercase;
            letter-spacing: 0.1em;
            color: $black-v2;
            cursor: pointer;
        }

        .formInput {
            width: 70%;

            .formControl {
                width: 100%;
                padding: 0 14px;
                font-family: $font-regular-2nd;
                font-size: $font-size-md;
                text-transform: initial;
                color: $black-v2;
                height: 47px;
                border: 1px solid #C4C4C4;
                box-shadow: none !important; // Override some browser default
                border-radius: 0;
                transition: .3s;

                &:focus {
                    border-color: $gold-dark;
                }

                &:disabled {
                    background-color: #F6F6F6;
                    cursor: not-allowed;
                }
            }

            .inputGroup {
                width: 100%;
                display: flex;
                flex-wrap: wrap;

                .inputGroupPrepend {
                    display: flex;
                    align-items: center;
                    text-align: center;
                    padding: 0;
                    background-color: $black-v2;

                    .inputGroupPrependText {
                        padding: 0 15px;
                        font-family: $font-sub-heading;
                        font-size: $font-size-lg;
                        letter-spacing: 1px;
                        color: $white;
                    }

                    .select2 {
                        height: 100%;
                        padding: 0;
                        border: none !important;

                        .select2-selection {
                            background-color: $black-v2;

                            .select2-selection__rendered {
                                height: 100%;
                                color: $white;
                            }
                        }
                    }

                    .select2-container--default .select2-selection--single .select2-selection__arrow b {
                        border-color: $white transparent transparent transparent;
                    }

                    .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
                        border-color: transparent transparent $white transparent;
                    }

                    &.countrySelection {
                        min-width: 100px;
                        background-color: $white;
                        border-top: 1px solid #C4C4C4;
                        border-left: 1px solid #C4C4C4;
                        border-bottom: 1px solid #C4C4C4;

                        .inputGroupPrependText {
                            width: 100%;
                            height: 100%;
                            padding: 0;
                            font-family: $font-sub-heading;
                            font-size: $font-size-md;

                            span {
                                font-family: $font-sub-heading;
                                font-size: $font-size-md;
                            }

                            .optionImage {
                                width: 25px;
                                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);

                                img {
                                    width: 100%;
                                }
                            }

                            @media only screen and (max-width: $screen-tablet) {
                                font-size: $font-size-xs;

                                .optionImage {
                                    width: 22px;
                                }

                                span {
                                    font-size: $font-size-xs;
                                }
                            }
                        }

                        .select2 {
                            .select2-selection {
                                background-color: $white;

                                .select2-selection__rendered {
                                    height: 100%;
                                    color: $black-v2;
                                }
                            }
                        }

                        .select2-container--default .select2-selection--single .select2-selection__arrow b {
                            border-color: $black-v2 transparent transparent transparent;
                        }

                        .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
                            border-color: transparent transparent $black-v2 transparent;
                        }
                    }
                }

                .formControl {
                    flex: 1;
                }
            }

            .passwordInput {
                position: relative;

                input[type="password"].formControl {
                    padding: 0 $gutter-xl 0 14px;
                }

                .togglePassword {
                    position: absolute;
                    top: 50%;
                    right: 12px;
                    transform: translateY(-50%);
                    cursor: pointer;

                    &:before {
                        content: "";
                        width: 20px;
                        height: 20px;
                        background-image: url("/images/version-2/icons/eye-slash.png");
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center;
                        float: right;
                    }

                    &.open:before {
                        background-image: url("/images/version-2/icons/eye.png");
                    }

                    &:after {
                        content: '';
                        display: block;
                        clear: both;
                    }
                }
            }

            /* Override Placeholder */
            input, select {
                &::placeholder {
                    color: #999;
                }
            }

            /* Override textarea */
            textarea.formControl {
                padding: 14px;
                height: 100px;
                box-shadow: none !important; // Override some browser default
                border-radius: 0;

                &::placeholder {
                    font-size: $font-size-md;
                    color: #999;

                    @media only screen and (max-width: $screen-tablet-max) {
                        font-size: $font-size-xs;
                        text-align: left;
                    }
                }
            }

            /* Override Select2 */
            .select2 {
                border: 1px solid #C4C4C4;
                box-shadow: none !important; // Override some browser default
                border-radius: 0;
                transition: .3s;

                &.select2-container--focus, &.select2-container--open {
                    border-color: $gold-dark;
                }

                .select2-selection {
                    height: 100%;
                    padding: 0 6px;

                    .select2-selection__rendered {
                        display: flex;
                        align-items: center;
                    }

                    .select2-selection__rendered, .select2-selection__arrow {
                        height: 47px;
                    }
                }
            }

            .select2-container--default {
                .select2-selection--single .select2-selection__arrow {
                    height: 100%;
                    top: 0;
                }

                .select2-selection--multiple {
                    border: none;

                    .select2-selection__choice {
                        margin-top: 0;
                        line-height: 1.7;
                        border: none;
                        border-radius: 0;
                    }
                }
            }
        }

        .formInfo {
            margin: $gutter-sm 0;
            font-family: $font-regular-2nd;
            font-weight: normal;
            font-size: $font-size-sm;
            text-transform: initial;
            letter-spacing: 0;

            a {
                color: $gold-dark;
                text-decoration: none;
            }
        }

        @media only screen and (max-width: $screen-tablet-max) {
            flex-direction: column;
            margin: $gutter-lg 0;
            min-height: 35px;
            font-size: $font-size-xs;

            .formLabel, .formInput {
                width: 100%;
            }

            .formLabel {
                margin-bottom: $gutter-md;
                font-size: $font-size-sm;
            }

            .formInput {
                .formControl {
                    height: 36px;
                    font-size: $font-size-xs;
                }

                .inputGroup {
                    .inputGroupPrepend {
                        height: 36px;
                    }
                }

                .passwordInput {
                    .togglePassword:before {
                        width: 17px;
                        height: 17px;
                    }
                }

                /* Override Select2 */
                .select2 {
                    .select2-selection .select2-selection__rendered, .select2-selection__arrow {
                        height: 36px;
                    }
                }
            }

            .formInfo {
                font-size: $font-size-xs;
            }
        }
    }
}

.checkboxGroup {
    display: flex;
    flex-wrap: wrap;

    &.fullWidth {
        flex-direction: column;
    }

    .checkboxContainer {
        position: relative;
        margin: 0 15px 15px 0;
        padding: 4px 0 0 30px;
        text-transform: uppercase;
        cursor: pointer;

        &.disabled {
            cursor: not-allowed;
        }

        /* Hide the browser's default checkbox */
        input[type="checkbox"] {
            position: absolute;
            opacity: 0;
            height: 0;
            width: 0;
        }

        /* Create a custom checkbox */
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 24px;
            width: 24px;
            border: 1px solid $black-v2;
        }

        /* When the checkbox is checked, change border color */
        input[type="checkbox"]:checked ~ .checkmark {
            border-color: $gold-dark;
        }

        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }

        /* Show the checkmark when checked */
        input[type="checkbox"]:checked ~ .checkmark:after {
            display: block;
        }

        /* Style the checkmark/indicator */
        .checkmark:after {
            top: 2px;
            left: 7px;
            width: 6px;
            height: 12px;
            border: solid $gold-dark;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
    }

    @media only screen and (max-width: $screen-tablet-max) {
        flex-direction: column;

        .checkboxContainer {
            margin: 0 0 15px;
            padding: 2px 0 0 25px;

            .checkmark {
                width: 16px;
                height: 16px;

                &:after {
                    top: 1px;
                    left: 4px;
                    width: 4px;
                    height: 8px;
                }
            }
        }
    }
}

.radioButtonGroup {
    display: flex;
    flex-wrap: wrap;

    &.fullWidth {
        flex-direction: column;
    }

    .radioButtonContainer {
        display: inline-block;
        position: relative;
        margin: 0 15px 15px 0;
        padding: 3px 0 0 30px;
        text-transform: uppercase;
        cursor: pointer;

        /* Hide the browser's default radio button */
        input[type="radio"] {
            position: absolute;
            opacity: 0;
            height: 0;
            width: 0;
        }

        /* Create a custom radio button */
        .checkmark {
            position: absolute;
            top: -1px;
            left: 0;
            height: 24px;
            width: 24px;
            border: 1px solid $black-v2;
            border-radius: 50%;
        }

        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }

        /* Show the checkmark when checked */
        input[type="radio"]:checked ~ .checkmark:after {
            display: block;
        }

        /* Style the checkmark/indicator */
        .checkmark:after {
            top: 50%;
            left: 50%;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: $black-v2;
            transform: translate(-50%, -50%);
        }
    }

    @media only screen and (max-width: $screen-tablet-max) {
        flex-direction: column;

        .radioButtonContainer {
            padding: 3px 0 0 25px;

            .checkmark {
                width: 20px;
                height: 20px;

                &:after {
                    width: 13px;
                    height: 13px;
                }
            }
        }
    }
}

.productCategoryGroup {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .productCategoryContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 20%;
        max-width: 140px;
        margin-bottom: $gutter-md;
        padding: $gutter-md;
        font-size: $font-size-md;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        text-align: center;
        border: 1px solid $black-v2;
        cursor: pointer;

        .iconWrapper {
            width: 28px;
            margin-bottom: $gutter-md;

            img {
                width: 100%;

                &.whenActiveIcon {
                    display: none;
                }
            }

            &.active {
                img {
                    display: none;

                    &.whenActiveIcon {
                        display: inherit;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: $screen-tablet-max) {
        .productCategoryContainer {
            flex-direction: row;
            justify-content: center;
            width: 48%;
            max-width: unset;
            margin-bottom: 15px;
            font-size: $font-size-xs;

            .iconWrapper {
                width: 22px;
                margin: 0 $gutter-md 0 0;
            }
        }
    }
}

.select2-container .select2-dropdown {
    &.address-area-codes-dropdown {
        width: max-content !important;

        .select2-results .select2-results__options .select2-results__option {
            display: flex;
            align-items: center;
            padding: $gutter-md 14px;
            font-family: $font-sub-heading;
            font-size: $font-size-md;

            span {
                font-family: $font-sub-heading;
                font-size: $font-size-md;
            }

            .optionImage {
                width: 25px;
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);

                img {
                    width: 100%;
                }
            }

            @media only screen and (max-width: $screen-tablet) {
                padding: $gutter-sm 14px;
                font-size: $font-size-xs;

                .optionImage {
                    width: 22px;
                }

                span {
                    font-size: $font-size-xs;
                }
            }
        }
    }
}
