@import '../_variables.scss';

/* Popups */
#packaging-example-popup {
    .contentWrapper {
        .imageWrapper {
            width: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

#popup-condition-guide {
    .popupContent {
        padding: 0;

        .title {
            border-bottom: 1px solid #C4C4C4;
        }

        .contentWrapper {
            .conditionGuide {
                margin-bottom: $gutter-lg;

                h5 {
                    padding: 0;
                    margin-bottom: $gutter-md;
                    font-family: $font-sub-heading-2nd;
                    font-size: $font-size-xl;
                    letter-spacing: 0.1em;
                    color: $black-v2;
                }

                p {
                    font-family: $font-regular-2nd;
                    font-size: $font-size-md;
                    letter-spacing: 0.1em;
                    color: $black-v2;
                }
            }
        }
    }

    @media only screen and (max-width: $screen-tablet) {
        .popupContent {
            .title {
                padding: 15px;
                font-size: $font-size-lg;
                text-align: center;
            }

            .contentWrapper {
                .conditionGuide {
                    h5 {
                        font-size: $font-size-md;
                    }

                    p {
                        font-size: $font-size-sm;
                    }
                }
            }
        }
    }
}

#country-popup {
    .popupContent {
        .contentWrapper .countryFlag {
            width: 40px;
            margin: 0 auto 15px;

            img {
                width: 100%;
            }
        }
    }
}

#blocked-account-popup {
    .popupContent {
        .contentWrapper {
            padding: $gutter-xl !important;

            .iconWrapper {
                width: 120px;
                margin: 0 auto $gutter-lg;

                img {
                    width: 100%;
                }
            }

            a.link {
                font-family: $font-sub-heading-2nd;
            }
        }

        @media only screen and (max-width: $screen-tablet-small) {
            .contentWrapper {
                padding: $gutter-lg !important;

                .iconWrapper {
                    width: 65px;
                }
            }
        }
    }
}