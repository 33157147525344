@import "elements/_form.scss";
@import "elements/_button.scss";
@import "member/_partial.scss";
@import "_mixin.scss";

.authContainer {
    max-width: 380px;
    margin: auto;

    &.otpPageContainer {
        max-width: 760px;
        text-align: center;
    }

    h2 {
        font-family: $font-sub-heading;
        font-size: 24px;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        color: $black-v2;

        @media only screen and (max-width: $screen-desktop-min) {
            font-size: $font-size-xl;
        }
    }

    .authPageDescription {
        padding: $gutter-sm 0;
        line-height: 20px;
        font-family: $font-regular-2nd;
        font-size: $font-size-sm;
        text-align: center;
        color: $black-v2;

        &.otpPageDescription {
            margin-top: 25px;
        }
    }

    .authWrapper {
        padding: $gutter-xl 0;

        @media only screen and (max-width: $screen-desktop-min) {
            padding: 15px;
        }

        .authContent {
            .form {
                margin-top: 30px;

                @media only screen and (max-width: $screen-desktop-min) {
                    padding: 0;
                }

                .forgotPassword {
                    font-family: $font-sub-heading;
                    font-size: $font-size-sm;
                    text-transform: uppercase;
                    letter-spacing: 0.1em;
                    color: $black-v2;
                    float: right;

                    &:after {
                        content: "";
                        display: block;
                        clear: both;
                    }

                    @media only screen and (max-width: $screen-desktop-min) {
                        margin-bottom: 25px;
                    }
                }

                .checkboxContainer {
                    padding: 0 0 0 35px;
                    font-size: $font-size-xs;
                    text-transform: initial;

                    a {
                        text-decoration: underline;
                    }

                    @media only screen and (max-width: $screen-tablet) {
                        padding: 0 0 0 25px;
                    }
                }
            }

            .bottomBox {
                margin-top: $gutter-sm;
                font-family: $font-regular-2nd;
                font-size: $font-size-sm;
                text-align: center;
                color: $black-v2;

                a {
                    font-family: $font-sub-heading;
                    text-transform: uppercase;
                    color: $black-v2 !important;
                }

                @media only screen and (max-width: $screen-desktop-min) {
                    margin-top: 25px;
                }
            }
        }
    }
}

@include otp-form;
