@import '../../_variables.scss';

.popupBox {
    position: relative;
    top: 50%;
    display: none;
    width: 600px;
    margin: 0 auto;
    transform: translateY(-50%);

    .popupContent {
        position: relative;
        max-height: 800px;
        padding: 0;
        background-color: $white;
        box-shadow: none;
        overflow-y: auto;

        .title {
            position: sticky;
            top: 0;
            left: 0;
            padding: $gutter-lg;
            font-family: $font-sub-heading;
            font-weight: bold;
            font-size: $font-size-xl;
            line-height: 20px;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 0.1em;
            color: $black-v2;
            background-color: $white;
            box-shadow: 0 0 6px rgba(29, 29, 29, 0.2);
            border-bottom: none;
            z-index: 2;
        }

        p {
            margin-bottom: 0;
            padding: 0;
            font-size: $font-size-md;
            color: $black-v2;
        }

        .contentWrapper {
            padding: $gutter-lg !important;
            font-size: $font-size-md;

            &.textCenter {
                text-align: center;
            }

            .form {
                padding: 0;
            }
        }

        .actionWrapper {
            position: sticky;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: $gutter-lg;
            background-color: $white;
            box-shadow: 0 0 6px rgba(29, 29, 29, 0.2);
            z-index: 2;

            .button {
                margin: 0;
            }
        }

        .closeBtn {
            position: absolute;
            top: 20px;
            right: 20px;
            width: 20px;
            height: 20px;
            z-index: 2;

            img {
                width: 100%;
            }
        }
    }

    @media only screen and (max-width: $screen-tablet) {
        width: 100%;
        max-width: 550px;
        padding: 15px;

        .popupContent {
            max-height: calc(100vh - 30px);

            .title {
                padding: 15px 35px;
                font-size: $font-size-lg;
                line-height: 18px;
            }

            .contentWrapper,
            .button,
            p, span {
                font-size: $font-size-sm;
            }

            .closeBtn {
                top: 18px;
                width: 14px;
                height: 14px;
            }
        }
    }

    @media only screen and (max-width: $screen-phone-small) {
        .popupContent {
            .title {
                font-size: $font-size-sm;
                line-height: 14px;
            }

            .contentWrapper,
            .button,
            p, span {
                font-size: $font-size-xs;
            }

            .closeBtn {
                top: 14px;
            }
        }
    }
}