/* Create mixin for all repetitive style block gradually */
@import "../_variables.scss";

@mixin product-preview-medium {
    .productPreviewContainer {
        min-height: 0;
        padding-bottom: $gutter-xl;

        .productImageWrapper {
            max-width: 300px;
            margin-right: $gutter-lg;

            .bigPhoto {
                height: 300px;
            }

            .photoThumbnails {
                width: 100%;
                margin: 15px 0 0;

                .nav {
                    display: none;
                }

                .thumbnails {
                    width: 100%;
                    overflow-x: auto;

                    .thumbnail {
                        margin-right: $gutter-md;
                    }
                }
            }
        }

        .productInfoWrapper {
            .info {
                &.productDesigner,
                &.productStyle {
                    font-size: $font-size-md;
                }

                &.productDetail,
                &.productPrice,
                &.additionalNotes,
                &.warningBlock {
                    padding: 15px 0;
                }

                &.productPrice {
                    .price, .earnings {
                        h5 {
                            font-size: $font-size-md;
                        }
                    }
                }

                &.additionalNotes {
                    padding: 0 0 15px 0;
                }

                &.warningBlock {
                    font-size: $font-size-xs;
                }
            }
        }
    }
}

@mixin product-preview-small {
    .productPreviewContainer {
        flex-direction: column;

        .productImageWrapper, .productInfoWrapper {
            width: 100%;
            max-width: 100%;
        }

        .productImageWrapper {
            margin: 0 0 25px;
        }

        .productInfoWrapper {
            .info {
                &.productPrice {
                    flex-direction: column;
                    height: auto;

                    .price, .earnings {
                        width: 100%;
                    }

                    .price {
                        margin-bottom: 15px;

                        p {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
}

@mixin reverse-button-order-on-mobile {
    display: flex;
    flex-direction: column-reverse;

    .button {
        margin: $gutter-sm 0 !important;
    }
}

@mixin our-promise {
    .ourPromiseWrapper {
        padding: $gutter-xl 0;

        .ourPromiseContent {
            display: flex;
            flex-wrap: wrap;
        }

        &:after {
            content: "";
            display: block;
            clear: both;
        }

        .ourPromiseContent {
            .ourPromise {
                width: 50%;
                margin-bottom: $gutter-lg;

                .content {
                    display: flex;
                    width: 100%; /* Fixer */

                    .imageWrapper {
                        width: 15%;
                        max-width: 90px;

                        img {
                            display: block;
                            margin: auto;
                            width: 100%;
                        }
                    }

                    .infoWrapper {
                        width: 85%;
                        padding: 0 $gutter-md;
                        font-family: $font-regular-2nd;
                        font-size: 16px;

                        .ourPromiseTitle {
                            padding-bottom: $gutter-md;
                            font-size: 20px;
                            font-family: $font-sub-heading;
                            text-transform: uppercase;
                            letter-spacing: 3px;
                        }
                    }
                }
            }

            @media only screen and (min-width: $screen-desktop-min) {
                .ourPromise .content {
                    padding: 0 80px;
                }
            }

            @media only screen and (min-width: 960px) {
                .ourPromise .content {
                    padding: 0;
                }
            }

            @media only screen and (max-width: $screen-tablet-max) {
                .ourPromise {
                    .content {
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        .imageWrapper, .infoWrapper {
                            width: 100%;
                        }

                        .infoWrapper {
                            font-size: $font-size-md;
                            text-align: center;

                            .ourPromiseTitle {
                                padding: $gutter-lg 0;
                                font-size: $font-size-lg;
                            }
                        }
                    }
                }
            }
        }
    }
}

@mixin override-style-for-transaction-detail-page {
    @media only screen and (min-width: 960px) {
        .mobileViewHeaderWrapper {
            display: none;
        }
    }

    @media only screen and (max-width: 959px) {
        .mobileViewHeaderWrapper .actionButton {
            top: 17px !important;
        }
    }

    .pageTitle {
        display: flex;
        align-items: center;
        margin: 16px 0;

        h3 {
            margin: 0 $gutter-md;
            font-family: $font-sub-heading-2nd;
            font-size: $font-size-xl;
            text-transform: uppercase;
            letter-spacing: 0.1em;
            color: $black-v2;
        }

        .previousButton, .invoiceButton {
            display: block;
            width: 15px;

            img {
                width: 100%;
            }
        }

        @media only screen and (max-width: $screen-tablet) {
            padding: 0 $gutter-md;

            .invoiceButton {
                width: 20px;
            }
        }
    }
}

@mixin override-style-for-invoice-page {
    header, footer, .breadcrumbBox, .headerTopInformation, .menuWrapper {
        display: none;
    }

    @media print {
        .mobileViewHeaderWrapper, .actionWrapper {
            display: none;
        }

        .memberContentWrapper {
            max-width: 100vw;

            .invoiceWrapper {
                margin: 0 auto !important;
                padding: 0 !important;

                .paymentSummary, .transactionPerSeller {
                    page-break-after: always !important;
                }
            }
        }
    }

    @media only screen and (min-width: 960px) {
        .memberContentWrapper {
            max-width: 960px !important;

            .mobileViewHeaderWrapper {
                display: none;
            }
        }
    }

    @media only screen and (min-width: 1280px) {
        .memberContentWrapper {
            max-width: 1200px !important;
        }
    }

    .actionWrapper {
        position: fixed;
        z-index: 1000;
        width: 100vw;
        top: 0;
        left: 0;
        right: 0;
        padding: $gutter-md 120px;
        background-color: $white;
        border-bottom: 1px solid $black-v2;

        .printButton, .saveButton {
            box-sizing: border-box;
            padding: $gutter-md $gutter-xl;
            font-family: $font-sub-heading;
            font-size: $font-size-md;
            font-weight: bold;
            text-transform: uppercase;
            color: $black-v2;
            line-height: 17px;
            background-color: $white;
            border: 1px solid $black-v2;
            transition: .3s;
            float: right;

            .icon {
                display: inline-block;
                width: 20px;
                height: 20px;
                margin-right: $gutter-sm;
                vertical-align: middle;

                img {
                    width: 100%;
                }
            }
        }

        &:after {
            content: "";
            display: block;
            clear: both;
        }
    }
}

@mixin transaction-partial-style {
    span {
        font-family: $font-regular-2nd;
    }

    .price {
        font-family: $font-sub-heading !important;
        font-size: $font-size-md !important;
        font-weight: bold;
        letter-spacing: 0.1em;
        color: $gold-dark;

        @media only screen and (max-width: $screen-tablet) {
            font-size: $font-size-sm !important;
        }
    }

    .oldPrice {
        font-family: $font-sub-heading !important;
        font-size: $font-size-sm !important;
        font-weight: bold;
        text-decoration: line-through;
        letter-spacing: 0.1em;
        color: #c4c4c4;

        @media only screen and (max-width: $screen-tablet) {
            font-size: 10px !important;
        }
    }

    .adminFee {
        color: #999999;
    }

    .decreasePrice {
        color: #76150C;
    }

    .customLink {
        font-weight: bold;
        text-decoration: underline;
        color: $black-v2;
    }

    .imageFrame {
        position: relative;
        width: 50px;
        height: 50px;
        margin-right: 15px;

        img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
            margin: auto;
        }
    }

    .status {
        width: auto !important;
        font-family: $font-sub-heading;
        text-transform: uppercase;
        letter-spacing: 0.1em;

        &-1 {
            color: $yellow-v2;
        }

        &-2 {
            color: $green-v2;
        }

        &-3 {
            color: #76150C;
        }

        &.shippingStatus {
            &-1, &-2, &-3 {
                color: $yellow-v2;
            }

            &-4, &-6 {
                color: $green-v2;
            }

            &-5 {
                color: #76150C;
            }
        }
    }

    .dangerBox {
        &.desktop {
            display: inline-block;
        }

        margin-top: $gutter-md;
        padding: $gutter-sm $gutter-xl;
        font-family: $font-sub-heading;
        font-weight: bold;
        font-size: 10px;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        color: #EE5253;
        background: rgba(238, 82, 83, 0.15);

        @media only screen and (max-width: $screen-tablet) {
            width: 100%;
            text-align: center;
        }
    }
}

@mixin transaction-detail-style {
    .transactionDetailWrapper {
        margin-top: $gutter-lg;
        font-family: $font-regular-2nd;
        font-size: $font-size-md;

        @media only screen and (max-width: $screen-tablet) {
            margin-top: 0;
            padding: 0 $gutter-md;
            font-size: $font-size-sm;
        }

        &:after {
            content: "";
            display: block;
            clear: both;
        }
    }
}

@mixin invoice-page-style {
    .invoiceWrapper {
        width: 700px;
        margin: $gutter-lg auto;
        padding: 100px 0 0;
        box-sizing: border-box;
        font-family: $font-regular-2nd;
        font-size: $font-size-sm;
        color: $black-v2;

        @media only screen and (max-width: $screen-tablet) {
            padding: 0;
        }

        @media only screen and (max-width: 720px) {
            width: auto !important;
        }

        @media only screen and (max-width: 430px) {
            transform: scale(0.59) translate(-35%, -35%);

            & > div {
                width: 720px !important;
            }
        }

        @media only screen and (max-width: 420px) {
            width: 720px !important;
        }

        @media only screen and (max-width: 380px) {
            transform: scale(0.45) translate(-58%, -62%);

            & > div {
                width: 790px !important;
            }
        }

        @media only screen and (max-width: $screen-phone-small) {
            transform: scale(0.38) translate(-78%, -82%);
        }

        .transactionPaymentsInvoice {
            width: 60%;
        }

        .price {
            font-size: $font-size-sm !important;
        }

        .oldPrice {
            font-size: 10px !important;
        }
    }
}

@mixin small-action-button($dimension, $url: "") {
    display: block;
    width: $dimension;
    height: $dimension;
    padding: $gutter-sm;
    border: 1px solid $black-v2;
    background: $white url(#{$url}) center / contain no-repeat;
    background-origin: content-box;
    color: $black-v2;

    &:hover {
        background-color: $lighter-gray-v2;
    }
}

@mixin spotlight {
    display: flex;
    align-items: center;
    margin-bottom: $gutter-xl;

    .information {
        width: 35%;
        padding-right: 25px;
        text-transform: uppercase;

        h3 {
            margin-bottom: $gutter-lg;
            font-family: $font-heading;
            font-size: 30px;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 0.15em;
        }

        .description {
            margin-bottom: $gutter-xl;
            font-family: $font-regular-2nd;
            font-size: $font-size-md;
            color: #363636;
            letter-spacing: 0.1em;
        }

        .link {
            display: none;
        }

        .button {
            margin: 0;
            font-size: 16px;
        }
    }

    .productCuration {
        width: 65%;

        .splide__arrows .splide__arrow--next {
            right: 0;
        }
    }

    @media only screen and (max-width: $screen-tablet) {
        position: relative;
        flex-direction: column;
        text-align: left;

        .information, .productCuration {
            width: 100%;
        }

        .information {
            padding: 0;

            h3, .description {
                width: 80%;
            }

            h3 {
                margin-bottom: $gutter-md;
                font-size: 20px;
                text-align: left;
            }

            .description {
                margin-bottom: 15px;
                font-size: $font-size-sm;
            }

            .link {
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                font-family: $font-sub-heading-2nd;
                font-size: $font-size-sm;
                text-decoration: underline;
                color: $black-v2;
            }

            .button {
                display: none;
            }
        }
    }
}

@mixin new-popup {
    .popupBox .popupContent {
        padding: 25px;

        h3 {
            padding-bottom: 25px;
        }

        .newContentWrapper {
            p {
                padding: 0 90px !important;
                line-height: 20px;
                font-size: $font-size-md;
            }

            .button {
                margin: 30px 0 0;
            }
        }

        @media only screen and (max-width: $screen-tablet) {
            padding: 0;

            h3 {
                padding: $gutter-lg;
                font-size: $font-size-sm;
            }

            .newContentWrapper {
                padding: 15px;

                p {
                    margin-top: 15px;
                    padding: 0 !important;
                    line-height: 14px;
                    font-size: $font-size-sm;
                }
            }
        }
    }
}

@mixin otp-form {
    .otpForm {
        margin-top: $gutter-lg;

        input[type="text"] {
            display: inline-block;
            width: 50px;
            height: 50px;
            margin: 0 8px;
            font-family: $font-sub-heading-2nd;
            font-size: 22px;
            text-align: center;
            border-bottom: 2px solid #C4C4C4;
            color: $black-v2;

            &:focus {
                border-color: $gold-dark;
            }
        }

        .otpAction {
            .button.button-link {
                display: inline-block;
                padding: 0 $gutter-md;
                width: max-content;
                font-family: $font-sub-heading-2nd;
                font-size: $font-size-md;
                color: #c4c4c4;

                &.active {
                    color: $black-v2;
                }

                &:hover {
                    text-decoration: none;
                }
            }

            #otp-countdown {
                font-size: $font-size-md;
            }
        }

        @media only screen and (max-width: $screen-tablet) {
            input[type="text"] {
                width: 35px;
                height: 35px;
                font-size: $font-size-xl;
            }

            .otpAction {
                .button.button-link {
                    font-size: $font-size-sm;
                }
            }
        }
    }
}


@mixin share-box {
    .shareBox {
        position: relative;
        width: max-content;
        padding: $gutter-sm 0;
        font-size: $font-size-sm;
        line-height: 14px;
        color: $black-v2;
        cursor: pointer;
        white-space: nowrap;
        z-index: 1;

        &:before {
            content: "";
            width: 11px;
            height: 13px;
            margin-right: $gutter-sm;

            background: {
                image: url("/images/version-2/icons/share-icon.svg");
                size: contain;
                repeat: no-repeat;
                position: center;
            }

            float: left;
        }

        &:hover {
            & .shareWrapper {
                display: block;
            }
        }

        .shareWrapper {
            display: none;
            position: absolute;
            top: 5px;
            right: -110px;
            width: 150px;
            padding: $gutter-md;
            background-color: $white;
            border: 0.5px solid $black-v2;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
            z-index: 2;

            .socialShare {
                display: inline-block;
                width: 25px;
                height: 25px;
                margin-right: $gutter-sm;

                &:last-child {
                    margin-right: 0;
                }

                img {
                    width: 100%;
                }
            }
            
            @media only screen and (max-width: $screen-tablet) {
                right: 0;
            }
        }
    }
}

@mixin new-tooltip {
    .tooltip {
        position: relative;
        display: inline-block;

        &:before {
            content: "";
            width: 14px;
            height: 14px;
            background: url("/images/version-2/icons/info.png") no-repeat center;
            background-size: contain;
            float: left;
            cursor: pointer;
        }

        &:hover .tooltipText {
            visibility: visible;
        }

        .tooltipText {
            visibility: hidden;
            position: absolute;
            top: 200%;
            left: 50%;
            width: 200px;
            min-height: 100px;
            margin-left: -100px;
            padding: 15px;
            line-height: 18px;
            font-family: $font-regular-2nd;
            font-size: $font-size-sm;
            text-transform: initial;
            text-wrap: initial;
            letter-spacing: 0;
            color: $black-v2;
            background-color: $white;
            border: 0.5px solid $black-v2;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
            z-index: 10000;

            .tooltipTitle {
                display: block;
                margin-bottom: $gutter-sm;
                font-family: $font-sub-heading-2nd !important;
            }

            &:after {
                content: "";
                position: absolute;
                left: 50%;
                bottom: 100%;
                margin: 0 0 0 -7px;
                border-width: 7px;
                border-style: solid;
                border-color: transparent transparent $black-v2 transparent;
            }

            @media only screen and (min-width: $screen-desktop-large) {
                top: 50%;
                left: 150%;
                margin-left: 0;
                transform: translateY(-50%);

                &:after {
                    top: 50%;
                    right: 100%;
                    left: initial;
                    bottom: initial;
                    margin: -7px 0 0 0;
                    border-color: transparent black transparent transparent;
                }
            }
        }
    }
}

@mixin tier-circle {
    .tierCircle {
        display: block;
        margin: 0 auto;
        width: 48px; // default width
        height: 48px; // default height

        &.small {
            width: 28px;
            height: 28px;
        }

        &.medium {
            width: 36px;
            height: 36px;
        }

        &Gold {
            background: url("/images/version-2/icons/gold-member-icon.png") no-repeat center;
            background-size: contain;
        }

        &Platinum {
            background: url("/images/version-2/icons/platinum-member-icon.png") no-repeat center;
            background-size: contain;
        }

        &Diamond {
            background: url("/images/version-2/icons/diamond-member-icon.png") no-repeat center;
            background-size: contain;
        }
    }
}

@mixin tier-benefit-list {
    .benefitListWrapper {
        .benefitList {
            &__table {
                width: 100%;
                border: none;

                thead {
                    font-family: $font-sub-heading-2nd;
                    font-size: $font-size-lg;
                    text-transform: uppercase;

                    .tierCircle {
                        margin: 0 auto 15px;
                    }
                }

                tr {
                    border-bottom: 1px solid #c4c4c4;
                    border-collapse: collapse;

                    th, td {
                        width: 15%;
                        padding: 25px $gutter-md;
                        text-align: center;

                        &:first-child {
                            width: 55%;
                            padding: 25px $gutter-xl 25px 0;
                            text-align: left;
                        }

                        .benefitTitle,
                        .benefitDescription {
                            display: block;
                            font-size: $font-size-lg;
                        }

                        .benefitTitle {
                            font-family: $font-sub-heading-2nd;
                        }

                        .benefitDescription {
                            margin-top: $gutter-sm;
                            font-family: $font-regular-2nd;
                            font-style: italic;
                        }
                    }
                }
            }

            &__accordion {
                .tierAccordion {
                    padding: 15px 0;
                    text-align: left;
                    color: $black-v2;
                    border-top: 1px solid #c4c4c4;

                    &:last-child {
                        border-bottom: 1px solid #c4c4c4;
                    }

                    .tierTitle {
                        position: relative;
                        display: flex;
                        align-items: center;
                        cursor: pointer;

                        &:before {
                            position: absolute;
                            right: 0;
                            content: "";
                            width: 20px;
                            height: 20px;
                            background: url("/images/version-2/icons/arrow-bottom-thin.svg") no-repeat center;
                            background-size: contain;
                            transition: .4s;
                        }

                        &.active:before {
                            transform: rotate(-180deg);
                        }

                        .tierCircle {
                            margin: 0 $gutter-md 0 0;
                        }

                        .tierName {
                            font-family: $font-sub-heading-2nd;
                            font-size: $font-size-lg;
                            text-transform: uppercase;
                            color: $black-v2;
                        }
                    }

                    .tierBenefitList {
                        display: none;

                        .benefitRow {
                            margin-left: 45px;
                            padding: 15px 0;
                            border-bottom: 1px solid #c4c4c4;

                            &:last-child {
                                padding: 15px 0 0;
                                border-bottom: none;
                            }

                            .benefitTitle {
                                font-family: $font-sub-heading-2nd;
                                font-size: $font-size-sm;
                            }

                            .benefitDescription {
                                margin-top: $gutter-sm;
                                font-family: $font-regular-2nd;
                                font-size: $font-size-xs;
                                font-style: italic;
                            }

                            @media only screen and (min-width: $screen-tablet) {
                                .benefitTitle {
                                    font-size: $font-size-md;
                                }

                                .benefitDescription {
                                    margin-top: 0;
                                    font-size: $font-size-sm;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@mixin voucher-code {
    width: max-content;
    margin-top: $gutter-sm;
    padding: 2px;
    font-family: $font-regular-2nd;
    font-size: $font-size-sm;
    background: rgba(79, 154, 118, 0.30);

    &:before {
        content: "";
        width: 10px;
        height: 8px;
        margin: 3px 2px 0 0;
        background: url("/images/version-2/icons/green-check-icon.svg") no-repeat center;
        float: left;
    }

    @media only screen and (max-width: $screen-tablet) {
        padding: 0 2px;
        font-size: $font-size-xs;
    }
}

@mixin voucher-value {
    font-family: $font-regular-2nd;
    font-size: $font-size-sm;
    letter-spacing: 0.1em;
    color: #999;

    @media only screen and (max-width: $screen-tablet) {
        font-size: $font-size-xs;
    }
}

@mixin voucher-error {
    width: max-content;
    margin-top: $gutter-sm;
    padding: 2px;
    font-family: $font-regular-2nd;
    font-size: $font-size-sm;
    background: rgba(238, 82, 83, 0.30);

    &:before {
        content: "";
        width: 10px;
        height: 8px;
        margin: 3px 2px 0 0;
        background: url("/images/version-2/icons/red-cross-icon.svg") no-repeat center;
        float: left;
    }

    @media only screen and (max-width: $screen-tablet) {
        padding: 0 2px;
        font-size: $font-size-xs;
    }
}

@mixin bank-transfer-label {
    max-width: max-content;
    padding: $gutter-sm;
    font-family: $font-regular-2nd;
    font-size: $font-size-sm;
    line-height: 17px;
    border-radius: 4px;
    background-color: #E9F3FB;

    &:before {
        content: "";
        width: 35px;
        height: 17px;
        margin-right: $gutter-sm;
        background: url("/images/version-2/icons/bank-transfer-icon-v2.png") no-repeat center;
        background-size: contain;
        float: left;
    }

    @media only screen and (max-width: $screen-tablet) {
        font-size: $font-size-xs;
    }
}

@mixin disclaimer-box {
    .disclaimerBox {
        margin: $gutter-xl 0;
        padding: $gutter-md 15px;
        font-family: $font-regular-2nd;
        font-size: $font-size-md;
        line-height: 16px;
        text-align: center;
        background: rgba(164, 123, 36, 0.1);
        border: 1px solid #9D7C3B;

        p {
            display: inline-block;
            padding-left: 25px;

            &:before {
                content: "";
                width: 16px;
                height: 16px;
                margin-left: -25px;
                background: url("/images/version-2/icons/info-gold.svg") no-repeat center;
                background-size: contain;
                float: left;
            }
        }

        @media only screen and (max-width: $screen-tablet) {
            margin: 25px 0;
            font-size: $font-size-xs;
            line-height: 12px;
            text-align: left;

            p {
                padding-left: 20px;

                &:before {
                    width: 12px;
                    height: 12px;
                    margin-left: -20px;
                }
            }
        }
    }
}