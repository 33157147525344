// Screen Sizes
$screen-phone-small: 320px;
$screen-phone: 480px;
$screen-tablet-small: 640px;
$screen-tablet: 768px;
$screen-desktop: 992px;
$screen-desktop-large: 1200px;

$screen-phone-small-max: $screen-phone-small;
$screen-phone-max: $screen-phone;
$screen-tablet-small-max: $screen-tablet-small;
$screen-tablet-max: $screen-tablet;
$screen-desktop-max: $screen-desktop;
$screen-desktop-large-max: $screen-desktop-large;

$screen-wide-min: $screen-desktop-large + 1;
$screen-desktop-large-min: $screen-desktop + 1;
$screen-desktop-min: $screen-tablet + 1;
$screen-tablet-min: $screen-tablet-small + 1;
$screen-tablet-small-min: $screen-phone + 1;
$screen-phone-min: $screen-phone-small + 1;

// Colors
$default: #585858;
//$gold: #C2912E;
$gold: #a37b2c;
$gold-dark: #a37b24;
$gray: #787878;
$light-gray: #adadad;
$lighter-gray: #e3e3e3;
$red: #EE5253;
$light-red: #FABFBF;
$green: #2DC127;
$white: #fff;
$black: #000;
$line-color: #D5D6D7;

// Colors V2
$dark-gray-v2: #231F20;
$gray-v2: #4A4A4A;
$light-gray-v2: #9B9B9B;
$lighter-gray-v2: #F5F6FA;
$light-gold-v2: rgba(163, 123, 36, .1);
$green-v2: #4F9A76;
$orange-v2: #F7941D;
$red-v2: #76150C;
$cream-v2: #F8F8F8;
$black-v2: #1D1D1D;
$yellow-v2: #f3c01c;

// Spaces
$gutter-xxs: 1px;
$gutter-xs: 2px;
$gutter-sm: 5px;
$gutter-md: 10px;
$gutter-lg: 20px;
$gutter-xl: 40px;

// Font Size
$font-size-xs: 10px;
$font-size-sm: 12px;
$font-size-md: 14px;
$font-size-lg: 16px;
$font-size-xl: 18px;

// Font Family
$font-heading: 'MrsEavesRoman-Regular';
$font-heading-2nd: 'MrsEaves';
$font-heading-bold: 'MrsEaves-Bold';
$font-heading-italic: 'MrsEaves-Italic';
$font-heading-bold-italic: 'MrsEavesXLSerifNarOT-HeavyItalic';
$font-sub-heading: 'Nunito-Bold';
$font-sub-heading-2nd: 'Nunito-ExtraBold';
$font-regular: 'Nunito-Light';
$font-regular-2nd: 'Nunito-Regular';
$font-serif-regular: 'PT-Serif';
$font-serif-bold: 'PT-Serif-Bold';
