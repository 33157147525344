@import "../elements/_button.scss";
@import "../elements/_popup.scss";
@import '../_override.scss';
@import '../_mixin.scss';

/* GLOBAL */
.tabWrapper {
    display: flex;
    font-family: $font-sub-heading;
    font-size: $font-size-lg;
    text-transform: uppercase;
    color: $black;

    .tab {
        flex-grow: 1;
        padding: $gutter-md 0;
        box-sizing: border-box;
        text-align: center;
        letter-spacing: 2px;

        a {
            color: $black-v2;
        }

        &:hover {
            border-bottom: 5px solid $gold-dark;
        }

        &--active {
            border-bottom: 5px solid $gold-dark;

            a {
                font-weight: bold;
                color: $gold;
            }
        }
    }

    @media only screen and (max-width: $screen-tablet-max) {
        overflow-x: auto;
        font-size: $font-size-sm;

        .tab {
            padding: $gutter-md;
        }
    }
}

.actionIcon {
    position: relative;
    width: 40px;
    height: 40px;
    cursor: pointer;

    &:before {
        content: '\2807';
        position: absolute;
        left: 15px;
        z-index: 1;
        font-size: 22px;
        color: $dark-gray-v2;
    }

    &:hover {
        .actionWrapper {
            display: block;
        }
    }

    .actionWrapper {
        display: none;
        position: absolute;
        top: 30px;
        right: 0;
        z-index: 2;
        width: max-content;
        background: $white;
        box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);

        a, button {
            display: block;
            width: 100%;
            margin: 15px;
            background: none;
            font-family: $font-sub-heading-2nd;
            font-size: $font-size-sm;
            text-align: left;
            text-decoration: none;
            text-transform: uppercase;
            color: $black-v2;
            border-bottom: 1px solid $light-gray-v2;
            transition: .3s;

            &:last-child {
                border-bottom: none;
            }
        }
    }
}

.statusBox {
    display: inline-block;
    margin-bottom: $gutter-sm;
    padding: 6px 10px;
    border-radius: 25px;
    font-family: $font-sub-heading;
    font-size: $font-size-sm;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    text-align: center;

    &.deliveryType, &.pending {
        background-color: $lighter-gray;
        color: $black;
    }

    &.status-1, &.status-3, &.status-4, &.needEdit, &.delisted {
        background-color: $orange-v2;
        color: $white;
    }

    &.status-2, &.status-6, &.active, &.listed {
        background-color: $green-v2;
        color: $white;
    }

    &.status-5, &.status-8 {
        background-color: $gold-dark;
        color: $white;
    }

    &.status-7, &.status-10, &.inactive, &.sold, &.rejected {
        background-color: $red-v2;
        color: $white;
    }

    &.status-11, &.status-12, &.general {
        background-color: $dark-gray-v2;
        color: $white;
    }
}

.flexor {
    display: flex;

    &-wrap {
        flex-wrap: wrap;
    }

    &-row {
        flex-direction: row;
    }

    &-column {
        flex-direction: column;
    }

    &-between {
        justify-content: space-between;
    }

    &-around {
        justify-content: space-around;
    }

    .flexorItem {
        &-20 {
            width: 20%;
        }

        &-25 {
            width: 25%;
        }

        &-30 {
            width: 30%;
        }

        &-33 {
            width: 33.333333%;
        }

        &-40 {
            width: 40%;
        }

        &-50 {
            width: 50%;
        }
    }
}

/* SINGLE */
.fullOptionWrapper {
    font-family: $font-regular-2nd;
    font-size: $font-size-md;
    letter-spacing: 0.1em;

    .sellingWrapper {
        display: flex;
        justify-content: space-between;

        .option {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            width: 48%;
            border: 1px solid #999999;
            padding: 25px $gutter-lg;
            color: $black-v2;

            .optionIcon {
                width: 80px;
                height: 80px;
                margin-bottom: 30px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
    }

    .preliminaryWrapper {
        width: 100%;
        margin: 50px 0;
        text-align: center;
        color: $black-v2;

        a {
            display: block;
            width: 120px;
            margin: 15px auto;
            padding: 0;
            font-family: $font-sub-heading;
        }
    }

    .optionTitle {
        margin-bottom: 14px;
        font-family: $font-sub-heading;
        font-size: $font-size-md;
        text-transform: uppercase;

        span {
            font-family: $font-sub-heading;
            font-size: $font-size-sm;
        }
    }

    @media only screen and (min-width: $screen-desktop-large) {
        font-size: $font-size-md;

        .sellingWrapper {
            .option {
                padding: $gutter-xl 30px;
            }
        }

        .optionTitle {
            font-size: $font-size-xl;

            span {
                font-size: $font-size-md;
            }
        }
    }

    @media only screen and (max-width: $screen-tablet-max) {
        margin: $gutter-md;
    }

    @media only screen and (max-width: $screen-phone-max) {
        .sellingWrapper {
            flex-direction: column;

            .option {
                width: 100%;
                margin: 0 0 $gutter-md;
            }
        }

        .preliminaryWrapper {
            margin: 30px 0;
        }
    }
}

.accessNotAllowedContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 750px;
    min-height: 600px;
    margin: 0 auto;
    text-align: center;
    color: $black-v2;

    .iconWrapper {
        width: 120px;
        margin: 0 auto;

        img {
            width: 100%;
        }
    }

    h2 {
        margin: 25px 0;
        font-family: $font-sub-heading-2nd;
        font-size: 20px;
        line-height: 24px;
        text-transform: uppercase;
        letter-spacing: 2px;
        color: $black-v2;
    }

    p {
        font-size: $font-size-md;
        line-height: 20px;
    }

    @media only screen and (max-width: $screen-tablet) {
        padding: 15px;

        .iconWrapper {
            width: 65px;
        }

        h2 {
            font-size: $font-size-md;
            line-height: 18px;
            letter-spacing: 1.4px;
        }

        p {
            font-size: $font-size-sm;
        }
    }
}

.productPreviewWrapper {
    .productPreviewContainer {
        display: flex;
        justify-content: space-between;
        min-height: 700px;

        .productImageWrapper {
            position: relative;
            width: 40%;
            max-width: 260px;
            margin-right: $gutter-xl;

            .bigPhoto {
                position: relative;
                width: 100%;
                height: 260px;
                border: 1px solid #E5E5E5;
            }

            .photoThumbnails {
                margin: 15px 0 0 -22px;
                width: 314px;
                height: 60px;
                box-sizing: border-box;

                .nav {
                    position: relative;
                    width: 12px;
                    height: 100%;
                    cursor: pointer;

                    &.left {
                        float: left;
                        margin-right: $gutter-md;
                    }

                    &.right {
                        float: right;
                        margin-left: $gutter-md;
                    }

                    img.icon {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 100%;
                        transform: translate(-50%, -50%);
                    }
                }

                .thumbnails {
                    overflow: hidden;
                    white-space: nowrap;
                    width: 260px;
                    height: 100%;
                    float: left;

                    .thumbnail {
                        position: relative;
                        display: inline-block;
                        width: 60px;
                        height: 100%;
                        margin-right: 15px;
                        box-sizing: border-box;
                        border: 1px solid #E5E5E5;
                        cursor: pointer;

                        &:last-child {
                            margin-right: 0;
                        }

                        &:hover, &.active {
                            border: 1px solid $black-v2;
                        }
                    }
                }

                &:after {
                    content: '';
                    display: block;
                    clear: both;
                }
            }

            .bigPhoto img,
            .photoThumbnails .thumbnails .thumbnail img {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                max-width: 100%;
                max-height: 100%;
                width: auto;
                height: auto;
                margin: auto;

                &.placeholderImage {
                    opacity: .5;
                }
            }
        }

        .productInfoWrapper {
            width: 60%;
            font-family: $font-regular-2nd;
            color: $black-v2;

            .info {
                h4 {
                    font-family: $font-sub-heading;
                    font-weight: bold;
                    font-size: $font-size-md;
                    text-transform: uppercase;
                    letter-spacing: 0.1em;
                    line-height: 2;
                }

                &.productDesigner,
                &.productStyle {
                    font-size: $font-size-xl;
                }

                &.productDesigner {
                    font-family: $font-sub-heading;
                    font-weight: bold;
                    text-transform: uppercase;
                    letter-spacing: 0.1em;
                }

                &.productStyle {
                    line-height: 1.5;
                }

                &.productDetail,
                &.productPrice,
                &.additionalNotes,
                &.warningBlock {
                    padding: 25px 0;
                }

                &.productDetail {
                    overflow-x: auto;
                    border-bottom: 1px solid #E5E5E5;

                    table {
                        margin-top: $gutter-md;
                        font-family: $font-regular-2nd;
                        font-size: $font-size-sm;

                        tr {
                            td {
                                padding: 3px 0;

                                &:first-child {
                                    width: 115px;
                                }
                            }
                        }
                    }
                }

                &.productPrice {
                    display: flex;
                    justify-content: space-between;

                    .price, .earnings {
                        width: 45%;

                        h5 {
                            padding-bottom: $gutter-sm;
                            font-family: $font-sub-heading;
                            font-weight: bold;
                            font-size: $font-size-xl;
                            text-transform: uppercase;
                            letter-spacing: 0.1em;
                            color: $gold-dark;
                        }
                    }
                }

                &.additionalNotes, &.warningBlock {
                    font-size: $font-size-sm;
                }

                &.additionalNotes {
                    padding: 0 0 25px 0;
                }

                &.warningBlock {
                    color: $gold-dark;
                    border-top: 1px solid #E5E5E5;

                    p {
                        padding-bottom: $gutter-md;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 1281px) {
        .productPreviewContainer {
            .productImageWrapper {
                max-width: 320px;

                .bigPhoto {
                    height: 320px;
                }

                .photoThumbnails {
                    width: 364px;

                    .thumbnails {
                        width: 320px;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 959px) {
        @include product-preview-medium;
    }

    @media only screen and (max-width: $screen-tablet-small) {
        @include product-preview-small;
    }
}

.locationInformation {
    display: flex;
    margin: $gutter-xl 0;
    padding: $gutter-lg 0;
    background-color: $light-gold-v2;
    font-family: $font-regular-2nd;
    font-size: $font-size-md;
    border: 1px solid $gold-dark;
    border-radius: 4px;

    &.fullWidth {
        display: block;
        padding: $gutter-lg;
    }

    .leftContent, .rightContent {
        flex: 1 1 50%;
        padding: 0 $gutter-lg;
    }

    .leftContent {
        border-right: 1px solid $light-gray-v2;
    }

    .exampleButton {
        font-family: $font-sub-heading;
        color: $gold-dark;
    }

    @media only screen and (max-width: $screen-tablet-max) {
        flex-direction: column;
        margin: $gutter-lg $gutter-md;
        padding: 0 12px;
        font-size: $font-size-sm;
        text-align: left;

        &.fullWidth {
            padding: 12px;
        }

        .leftContent, .rightContent {
            padding: $gutter-lg 0;
        }

        .leftContent {
            border-right: none;
            border-bottom: 1px solid $light-gray-v2;
        }
    }
}

.footerAction {
    margin-top: $gutter-lg;
    border: 1px solid $light-gray;
    padding: $gutter-md;
}

/* OVERRIDE */
.pagingBox {
    position: relative;
    height: 50px;
    margin: $gutter-xl 0 $gutter-lg;
    background: $white;

    ul {
        padding-top: $gutter-md;
        font-family: $font-sub-heading;
        font-size: $font-size-md;
        text-align: center;

        li {
            display: inline-block;
            width: 30px;
            height: 30px;
            margin: 0 3px;
            line-height: 28px;

            &:hover, &.active {
                background-color: $black-v2;

                a, label, span {
                    color: $white;
                }
            }

            &.normal, a, label, span {
                /* Unset Inherited Style */
                width: unset;
                height: unset;
                line-height: unset;
                border-radius: unset;
                display: unset;
                margin: unset;
                /* End Unset*/
                color: $black-v2;
            }

            a.normal:hover, a.selected, a:hover, &.active span {
                background-color: transparent;
                font-family: $font-sub-heading !important;
                color: $white;
            }
        }
    }
}

/* Member Filter Combobox */
.topAction {
    position: absolute;
    top: $gutter-lg;
    right: 0;
    display: flex;
    justify-content: flex-end;

    .customSelectBox {
        width: 220px;
        margin-left: $gutter-md;
        border: 1px solid $black-v2;
        line-height: 4;

        span {
            font-family: $font-sub-heading;
            font-size: $font-size-md;
            letter-spacing: 1.2px;
            color: $black-v2;
        }

        .select2-selection__rendered,
        .select2-selection__arrow {
            height: 100%;
            font-family: $font-sub-heading;
            font-weight: bold;
            font-size: $font-size-md;
            line-height: 26px;
            text-transform: uppercase;
            color: $black-v2;
        }

        .select2-selection__arrow {
            top: 0;
        }
    }

    @media only screen and (max-width: 959px) {
        position: static;
        padding: $gutter-md;

        .customSelectBox {
            line-height: 3;

            span,
            .select2-selection__rendered,
            .select2-selection__arrow {
                font-size: $font-size-sm;
            }
        }
    }

    @media only screen and (max-width: $screen-phone-max) {
        flex-direction: column;

        .customSelectBox {
            width: 100%;
            margin: 0 0 $gutter-md 0;
        }
    }
}

/* Tabs */
.tabsWrapper {
    display: flex;

    .tab {
        width: 50%;
        padding: 15px $gutter-sm;
        font-family: $font-sub-heading;
        font-size: $font-size-xl;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        line-height: 22px;
        letter-spacing: 0.1em;
        color: $black-v2;
        border-bottom: 4px solid #c4c4c4;

        &:hover, &.active {
            border-color: $black-v2;
        }
    }

    @media only screen and (max-width: $screen-tablet) {
        .tab {
            padding: $gutter-md $gutter-sm;
            font-size: $font-size-sm;
            line-height: 14px;
        }
    }
}

/* FIXER */
.profileWrapper {
    h3 {
        margin: 16px 0;
    }
}

@media only screen and (max-width: $screen-tablet-max) {
    span.mobile {
        padding-bottom: 3px;
        font-size: 10px;
        color: $gray-v2 !important;
    }
}