@import "../../_variables.scss";

.button {
    display: inline-block;
    margin: $gutter-lg 0;
    padding: 0 ($gutter-lg + $gutter-md);
    font-family: $font-sub-heading;
    font-size: $font-size-lg;
    text-align: center;
    text-transform: uppercase;
    line-height: 2.5;
    letter-spacing: 0;
    white-space: nowrap; /* Safari adjustment */

    &:disabled {
        opacity: .5;
        cursor: not-allowed;
        color: $light-gray !important;
        background-color: $gray !important;
        border: 1px solid $gray !important;
    }

    &-small {
        font-size: $font-size-sm;
    }

    &-right {
        float: right;
    }

    &-full {
        width: 100%;
    }

    &-center {
        display: block;
        margin: $gutter-lg auto;
    }

    &-big {
        min-width: 320px;
        max-width: max-content;
    }

    &-no-margin {
        margin: 0 !important;
    }

    &-auto-width {
        max-width: max-content !important;
    }

    @media only screen and (max-width: $screen-tablet-max) {
        display: block;
        width: 100%;
        margin: $gutter-lg 0 $gutter-sm;
        padding: $gutter-sm $gutter-md;
        font-size: $font-size-sm;

        &-small {
            padding: 0;
            font-size: $font-size-xs;
        }

        &-right {
            margin: $gutter-sm 0 $gutter-lg;
        }

        &-big {
            min-width: 100%;
        }
    }

    &-link {
        font-family: $font-sub-heading-2nd;
        text-decoration: none;
        border: none;
        background: none;
        color: $black-v2;

        &:hover {
            background: none;
            text-decoration: underline;
        }
    }

    &-primary {
        background-color: $black-v2;
        border: 1px solid $black-v2;
        color: $white;

        &:hover {
            background-color: $white;
            color: $black-v2;
        }

        &-faded {
            background-color: $white;
            border: 1px solid $black-v2;
            color: $black-v2;

            &:hover {
                background-color: $black-v2;
                color: $white;
            }

            &.button-no-hover:hover {
                background-color: $white;
                color: $black-v2;
            }
        }
    }

    &-secondary {
        background-color: $lighter-gray;
        border: 1px solid $lighter-gray;
        color: $black-v2;

        &:hover {
            background-color: $light-gray;
        }
    }

    &-gold {
        background-color: $gold-dark;
        border: 1px solid $gold-dark;
        color: $white;

        &:hover {
            background-color: $white;
            color: $gold-dark;
        }

        &-faded {
            background-color: $light-gold-v2;
            border: 1px solid $gold-dark;
            color: $gold-dark;

            &:hover {
                background-color: $gold-dark;
                color: $white;
            }
        }
    }

    &-white {
        box-shadow: 0 3px 5px 1px $lighter-gray;
        background-color: $white;
        border: 1px solid $white;
        color: $black-v2;

        &:hover {
            background-color: $lighter-gray;
            border-color: $lighter-gray;
        }
    }

    &:after {
        content: "";
        display: block;
        clear: both;
    }
}
